import React, { useState, useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { FaEdit, FaPlus, FaTrash, FaChevronLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import CTPaginate from '../../components/CTPaginate';
import {
	useGetProductsQuery,
	useCreateProductMutation,
	useDeleteProductMutation,
} from '../../slices/productsApiSlice';
import Meta from '../../components/Meta';

const ProductListScreen = () => {
	const { pageNumber, keyword } = useParams();
	const [currentPage, setCurrentPage] = useState(Number(pageNumber) || 1);
	const { data, isLoading, error, refetch } = useGetProductsQuery({
		keyword,
		pageNumber: currentPage,
		largePageSize: 20,
	});
	const navigate = useNavigate();

	const handleGoBack = () => {
		navigate(-1);
	};

	const [createProduct, { isLoading: loadingCreate }] =
		useCreateProductMutation();

	const createProductHandler = async () => {
		if (window.confirm('Are you sure you want to create a new product?')) {
			try {
				await createProduct();
				refetch();
				toast.success('Product created successfully');
			} catch (err) {
				if (
					err?.data?.message === 'TypeError: Failed to fetch' ||
					err.error === 'TypeError: Failed to fetch'
				) {
					toast.error(
						'You are currently offline. Please connect to the internet and try again.',
					);
				} else if (err?.data?.message) {
					// Display the error message from the backend
					toast.error(err?.data?.message);
				} else if (err.status === 400) {
					toast.error('Bad request. Please check your input and try again.');
				} else if (err.status === 500) {
					toast.error('Internal server error. Please try again later.');
				} else {
					toast.error(
						err?.data?.message ||
							err.error ||
							'An unexpected error occurred. Please try again.',
					);
				}
			}
		}
	};

	const [deleteProduct, { isLoading: loadingDelete }] =
		useDeleteProductMutation();

	const deleteHandler = async (id) => {
		if (window.confirm('Are you sure you want to delete this product?')) {
			try {
				await deleteProduct(id);
				refetch();
				toast.success('Product deleted successfully');
			} catch (err) {
				if (
					err?.data?.message === 'TypeError: Failed to fetch' ||
					err.error === 'TypeError: Failed to fetch'
				) {
					toast.error(
						'You are currently offline. Please connect to the internet and try again.',
					);
				} else if (err?.data?.message) {
					// Display the error message from the backend
					toast.error(err?.data?.message);
				} else if (err.status === 400) {
					toast.error('Bad request. Please check your input and try again.');
				} else if (err.status === 500) {
					toast.error('Internal server error. Please try again later.');
				} else {
					toast.error(
						err?.data?.message ||
							err.error ||
							'An unexpected error occurred. Please try again.',
					);
				}
			}
		}
	};

	useEffect(() => {
		// Scroll to the top of the page
		window.scrollTo(0, 0);
	});

	return (
		<>
			<Meta title='All Products - Cabsons Nutra Biohealth' />
			<Button className='btn btn-light my-3 ' onClick={handleGoBack}>
				<FaChevronLeft />
				Previous Page
			</Button>
			<Link className='btn btn-light my-3 ms-3' to='/'>
				Home
			</Link>
			<Row className='align-items-center'>
				<Col>
					<h1>Products</h1>
				</Col>
				<Col className='text-end'>
					<Button className='my-3' onClick={createProductHandler}>
						<FaPlus /> Create Product
					</Button>
				</Col>
			</Row>

			{loadingCreate && <Loader />}
			{loadingDelete && <Loader />}

			{isLoading ? (
				<Loader />
			) : error ? (
				error?.data?.message === 'TypeError: Failed to fetch' ||
				error.error === 'TypeError: Failed to fetch' ? (
					<Message variant='danger'>
						You are currently offline. Please connect to the internet and try
						again.
					</Message>
				) : (
					<Message variant='danger'>
						{error?.data?.message ||
							error.error ||
							'An error occurred, please try again'}
					</Message>
				)
			) : (
				<>
					<Table striped bordered hover responsive className='table-lg'>
						<thead>
							<tr>
								<th>PRODUCT ID</th>
								<th>NAME</th>
								<th>PRICE</th>
								<th>CATEGORY</th>
								<th>BRAND</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{data.products.map((product) => (
								<tr key={product._id}>
									<td>{product._id}</td>
									<td>{product.name}</td>
									<td>${product.price}</td>
									<td>{product.category}</td>
									<td>{product.brand}</td>
									<td>
										<LinkContainer to={`/admin/product/${product._id}/edit`}>
											<Button variant='light' className='btn-sm mx-2'>
												<FaEdit />
											</Button>
										</LinkContainer>
										<Button
											variant='danger'
											className='btn-sm'
											onClick={() => deleteHandler(product._id)}>
											<FaTrash style={{ color: 'white' }} />
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
					{data && data.products && (
						<CTPaginate
							pageNumber={currentPage}
							setPageNumber={setCurrentPage}
							data={data}
						/>
					)}
				</>
			)}
		</>
	);
};

export default ProductListScreen;
