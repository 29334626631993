// Desc: Private route for admin users
// To be used in the App component to protect admin routes

import { Navigate, Outlet } from 'react-router-dom'; // Navigate to redirect to another route and Outlet to render nested routes
import { useSelector } from 'react-redux'; // useSelector to select data from the store state

// AdminRoute component
const AdminRoute = () => {
	// Get userInfo from the store state
	const { userInfo } = useSelector((state) => state.auth);

	// If userInfo is available and user is admin, render the nested routes, else redirect to login
	return userInfo && userInfo.isAdmin ? (
		<Outlet /> // Render nested routes
	) : (
		<Navigate to='/login' replace /> // Redirect to login
	);
};

// Export the AdminRoute component
export default AdminRoute;
