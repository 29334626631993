import { apiSlice } from './apiSlice';
import { ORDERS_URL, PAYSTACK_VERIFY_URL } from '../constants';

// Orders API Slice
export const ordersApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		// Create Order
		createOrder: builder.mutation({
			query: (order) => ({
				url: ORDERS_URL,
				method: 'POST',
				body: { ...order },
			}),
		}),

		// Get order details
		getOrderDetails: builder.query({
			query: (id) => ({
				url: `${ORDERS_URL}/${id}`,
			}),
			keepUnusedDataFor: 5,
		}),

		// Verify Paystack transaction
		verifyPaystackTransaction: builder.query({
			query: (reference) => ({
				url: `${PAYSTACK_VERIFY_URL}/${reference}`,
			}),
			keepUnusedDataFor: 5,
		}),

		// Pay Order
		payOrder: builder.mutation({
			query: ({ orderId, paymentResult }) => ({
				url: `${ORDERS_URL}/${orderId}/pay`,
				method: 'PUT',
				body: { paymentResult },
			}),
		}),

		// Get my orders by the user
		getMyOrders: builder.query({
			query: () => ({
				url: `${ORDERS_URL}/mine`,
			}),
			keepUnusedDataFor: 5,
		}),

		// Get all orders by the admin
		getOrders: builder.query({
			query: (params) => ({
				url: ORDERS_URL,
				params,
			}),
			providesTags: ['Orders'],
			keepUnusedDataFor: 5,
		}),

		// Update order to delivered by the admin
		deliverOrder: builder.mutation({
			query: (orderId) => ({
				url: `${ORDERS_URL}/${orderId}/deliver`,
				method: 'PUT',
			}),
		}),
		// Check if a user has purchased a product
		hasPurchased: builder.query({
			query: (productId) => ({
				url: `${ORDERS_URL}/haspurchased/${productId}`,
			}),
		}),
	}),
});

export const {
	useCreateOrderMutation,
	useGetOrderDetailsQuery,
	useVerifyPaystackTransactionQuery,
	usePayOrderMutation,
	useGetMyOrdersQuery,
	useGetOrdersQuery,
	useDeliverOrderMutation,
	useHasPurchasedQuery,
} = ordersApiSlice;
