import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const SearchBox = () => {
	const navigate = useNavigate();
	const urlParams = new URLSearchParams(window.location.search);
	const urlKeyword = urlParams.get('q');
	const [keyword, setKeyword] = useState(urlKeyword || '');

	const submitHandler = (e) => {
		e.preventDefault();
		if (keyword.trim()) {
			if (window.location.pathname.startsWith('/admin/productlist')) {
				navigate(`/admin/productlist/search/${keyword.trim()}`);
			} else {
				navigate(`/search/${keyword.trim()}`);
			}
			setKeyword('');
			window.scrollTo(0, 0);
			// Track the event
			window.gtag('event', 'search', {
				event_category: 'User Interaction',
				event_label: `Searched for ${keyword.trim()}`,
			});
		} else {
			toast.error('Please enter a search word');
		}
	};

	return (
		<Form onSubmit={submitHandler} className='d-flex'>
			<Form.Control
				type='text'
				name='q'
				onChange={(e) => setKeyword(e.target.value)}
				value={keyword}
				placeholder='Search Products...'
				className='mr-sm-2 ml-sm-5'></Form.Control>
			<Button
				type='submit'
				variant='outline-success'
				className='p-2 mx-2 search-button'>
				Search
			</Button>
		</Form>
	);
};

export default SearchBox;
