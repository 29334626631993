import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import assets from '../config.js'; // import the error image

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		console.error(error, errorInfo);
	}

	componentWillUnmount() {
		// Reset the error state when the component unmounts
		this.setState({ hasError: false });
	}

	// Test to be done in component

	// Above the return statement
	// const [shouldThrowError, setShouldThrowError] = useState(false);
	// if (shouldThrowError) {
	// 	// This will cause an error to be thrown
	// 	throw new Error('Test error');
	// }

	// Inside the return statement
	// <button onClick={() => setShouldThrowError(true)}>
	// 			Throw test error
	// 		</button>

	render() {
		if (this.state.hasError) {
			return (
				<Container>
					<Row className='justify-content-md-center mt-5'>
						<Col xs lg='6'>
							<Image src={assets.oops} fluid />
						</Col>
					</Row>
					<Row className='justify-content-md-center text-center'>
						<Col xs lg='6'>
							<h1>Oops, something went wrong.</h1>
							<p>
								We're sorry for the inconvenience. Our team has been notified
								and will fix the issue as soon as possible.
							</p>
							<div>
								<button
									onClick={() => window.location.reload()}
									className='btn btn-outline-primary mb-3'>
									Reload page
								</button>
							</div>
							<div>
								<Link to='/' className='btn btn-outline-primary mb-3'>
									Go back to home page
								</Link>
							</div>
						</Col>
					</Row>
				</Container>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
