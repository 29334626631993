import React, { useEffect, useState } from 'react';
import { Table, Form, Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FaTimes, FaCheck, FaChevronLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { useProfileMutation } from '../slices/usersApiSlice';
import { setCredentials } from '../slices/authSlice';
import { useGetMyOrdersQuery } from '../slices/ordersApiSlice';
import Meta from '../components/Meta';

// Profile screen component
const ProfileScreen = () => {
	// Local state for the form fields
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	// Dispatch function from the Redux store
	const dispatch = useDispatch();

	// Get the navigate function
	const navigate = useNavigate();

	// Function to handle the 'Go Back' button click
	const handleGoBack = () => {
		navigate(-1);
	};

	// Get the user info from the Redux store
	const { userInfo } = useSelector((state) => state.auth);

	// Update Profile mutation from the users API slice
	const [updateProfile, { isLoading: loadingUpdateProfile }] =
		useProfileMutation();

	// Get the user's orders from the API
	const { data: orders, isLoading, error } = useGetMyOrdersQuery();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// Update the form fields with the user info
	useEffect(() => {
		if (userInfo) {
			setName(userInfo.name);
			setEmail(userInfo.email);
		}
	}, [userInfo.email, userInfo.name, userInfo]);

	// Function to handle the form submit
	const submitHandler = async (e) => {
		e.preventDefault();
		// Check if the passwords match and call the updateProfile API endpoint
		if (password !== confirmPassword) {
			toast.error('Passwords do not match');
		} else {
			try {
				const res = await updateProfile({
					_id: userInfo._id,
					name,
					email,
					password,
				}).unwrap();

				dispatch(setCredentials({ ...res }));
				toast.success('Profile updated successfully');
				// Track the event
				window.gtag('event', 'profile_update', {
					event_category: 'User Interaction',
					event_label: 'Updated profile',
				});
			} catch (err) {
				if (
					err?.data?.message === 'TypeError: Failed to fetch' ||
					err.error === 'TypeError: Failed to fetch'
				) {
					toast.error(
						'You are currently offline. Please connect to the internet and try again.',
					);
				} else if (err?.data?.message) {
					// Display the error message from the backend
					toast.error(err?.data?.message);
				} else if (err.status === 400) {
					toast.error('Bad request. Please check your input and try again.');
				} else if (err.status === 500) {
					toast.error('Internal server error. Please try again later.');
				} else {
					toast.error(
						err?.data?.message ||
							err.error ||
							'An unexpected error occurred. Please try again.',
					);
				}
			}
		}
	};

	return (
		<>
			<Meta
				title={`${name}'s Profile - Cabsons Nutra Biohealth`}
				description='Update your profile information and view your orders'
				keywords='user profile, update profile, view orders'
				image={`${window.location.origin}/images/cabsons-512.png`}
			/>

			<Row>
				<Col md={4}>
					<Button className='btn btn-light my-3 ' onClick={handleGoBack}>
						<FaChevronLeft />
						Previous Page
					</Button>
					<Link className='btn btn-light my-3 ms-3' to='/'>
						Home
					</Link>
					<h2>User Profile</h2>
					<p>
						<em>
							Leave fields you don't want to update <strong>as it is</strong>
						</em>
					</p>

					<Form onSubmit={submitHandler}>
						<Form.Group className='my-2' controlId='name'>
							<Form.Label>Name</Form.Label>
							<Form.Control
								type='name'
								placeholder='Enter name'
								value={name}
								onChange={(e) => setName(e.target.value)}></Form.Control>
						</Form.Group>

						<Form.Group className='my-2' controlId='email'>
							<Form.Label>Email Address</Form.Label>
							<Form.Control
								type='email'
								placeholder='Enter email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}></Form.Control>
						</Form.Group>

						<Form.Group className='my-2' controlId='password'>
							<Form.Label>Password</Form.Label>
							<Form.Control
								type='password'
								placeholder='Enter password'
								value={password}
								onChange={(e) => setPassword(e.target.value)}></Form.Control>
						</Form.Group>

						<Form.Group className='my-2' controlId='confirmPassword'>
							<Form.Label>Confirm Password</Form.Label>
							<Form.Control
								type='password'
								placeholder='Confirm password'
								value={confirmPassword}
								onChange={(e) =>
									setConfirmPassword(e.target.value)
								}></Form.Control>
						</Form.Group>

						<Button type='submit' variant='primary'>
							Update
						</Button>
						{/* Loader */}
						{loadingUpdateProfile && <Loader />}
					</Form>
				</Col>
				<Col md={8}>
					<h2>My Orders</h2>
					{isLoading ? (
						<Loader />
					) : error ? (
						error?.data?.message === 'TypeError: Failed to fetch' ||
						error.error === 'TypeError: Failed to fetch' ? (
							<Message variant='danger'>
								You are currently offline. Order details are not available.
								Please connect to the internet and try again.
							</Message>
						) : (
							<Message variant='danger'>
								{error?.data?.message || error.error}
							</Message>
						)
					) : (
						<Table striped hover responsive className='table-lg'>
							<thead>
								<tr>
									<th>ORDER ID</th>
									<th>DATE</th>
									<th>TOTAL</th>
									<th>PAID</th>
									<th>DELIVERED</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{orders.map((order) => (
									<tr key={order._id}>
										<td>{order._id}</td>
										<td>{order.createdAt.substring(0, 10)}</td>
										<td>{order.totalPrice}</td>
										<td>
											{order.isPaid ? (
												<>
													<FaCheck style={{ color: 'green' }} />
													{order.paidAt.substring(0, 10)}
												</>
											) : (
												<FaTimes style={{ color: 'red' }} />
											)}
										</td>
										<td>
											{order.isDelivered ? (
												<>
													<FaCheck style={{ color: 'green' }} />
													{order.deliveredAt.substring(0, 10)}
												</>
											) : (
												<FaTimes style={{ color: 'red' }} />
											)}
										</td>
										<td>
											<LinkContainer to={`/order/${order._id}`}>
												<Button className='btn-sm' variant='light'>
													Details
												</Button>
											</LinkContainer>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					)}
				</Col>
			</Row>
		</>
	);
};

export default ProfileScreen;
