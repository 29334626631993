import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import FormContainer from '../../components/FormContainer';
import { toast } from 'react-toastify';
import {
	useGetUserDetailsQuery,
	useUpdateUserMutation,
} from '../../slices/usersApiSlice';
import Meta from '../../components/Meta';

const UserEditScreen = () => {
	const { id: userId } = useParams();
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [isAdmin, setIsAdmin] = useState(false);

	const {
		data: user,
		isLoading,
		error,
		refetch,
	} = useGetUserDetailsQuery(userId);

	const navigate = useNavigate();

	const [updateUser, { isLoading: loadingUpdate }] = useUpdateUserMutation();

	const submitHandler = async (e) => {
		e.preventDefault();
		if (
			isAdmin !== user.isAdmin &&
			!window.confirm('Are you sure you want to change the admin status?')
		) {
			return;
		}
		try {
			await updateUser({ userId, name, email, isAdmin });
			toast.success('user updated successfully');
			refetch();
			navigate('/admin/userlist');
		} catch (err) {
			if (
				err?.data?.message === 'TypeError: Failed to fetch' ||
				err.error === 'TypeError: Failed to fetch'
			) {
				toast.error(
					'You are currently offline. Please connect to the internet and try again.',
				);
			} else if (err?.data?.message) {
				// Display the error message from the backend
				toast.error(err?.data?.message);
			} else if (err.status === 400) {
				toast.error('Bad request. Please check your input and try again.');
			} else if (err.status === 500) {
				toast.error('Internal server error. Please try again later.');
			} else {
				toast.error(
					err?.data?.message ||
						err.error ||
						'An unexpected error occurred. Please try again.',
				);
			}
		}
	};

	useEffect(() => {
		if (user) {
			setName(user.name);
			setEmail(user.email);
			setIsAdmin(user.isAdmin);
		}
	}, [user]);


	return (
		<>
			<Meta title='Edit User - Cabsons Nutra Biohealth' />
			<Link to='/admin/userlist' className='btn btn-light my-3'>
				Go Back
			</Link>
			<FormContainer>
				<h1>Edit User</h1>
				{isLoading ? (
					<Loader />
				) : error ? (
					error?.data?.message === 'TypeError: Failed to fetch' ||
					error.error === 'TypeError: Failed to fetch' ? (
						<Message variant='danger'>
							You are currently offline. User derails are not available since
							you have not visited this page before while online. Please connect
							to the internet and try again.
						</Message>
					) : (
						<Message variant='danger'>
							{error?.data?.message ||
								error.error ||
								'An error occurred, please try again'}
						</Message>
					)
				) : (
					<Form onSubmit={submitHandler}>
						<Form.Group className='my-2' controlId='name'>
							<Form.Label>Name</Form.Label>
							<Form.Control
								type='name'
								placeholder='Enter name'
								value={name}
								onChange={(e) => setName(e.target.value)}></Form.Control>
						</Form.Group>

						<Form.Group className='my-2' controlId='email'>
							<Form.Label>Email Address</Form.Label>
							<Form.Control
								type='email'
								placeholder='Enter email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}></Form.Control>
						</Form.Group>

						<Form.Group className='my-2' controlId='isadmin'>
							<Form.Check
								type='checkbox'
								label='Is Admin'
								checked={isAdmin}
								onChange={(e) => setIsAdmin(e.target.checked)}></Form.Check>
						</Form.Group>

						{loadingUpdate && <Loader />}
						<Button type='submit' variant='primary' disabled={loadingUpdate}>
							Update
						</Button>
					</Form>
				)}
			</FormContainer>
		</>
	);
};

export default UserEditScreen;
