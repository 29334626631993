import React from 'react';
import { Helmet } from 'react-helmet-async';

const Meta = ({ title, description, keywords, image, url }) => {
	return (
		<Helmet>
			<title>{title}</title>
			<meta name='description' content={description} />
			<meta name='keyword' content={keywords} />
			<meta property='og:title' content={title} />
			<meta property='og:description' content={description} />
			<meta property='og:image' content={image} />
			<meta property='og:url' content={url} />
			<meta name='twitter:card' content='summary_large_image' />
			<meta name='twitter:title' content={title} />
			<meta name='twitter:description' content={description} />
			<meta name='twitter:image' content={image} />
		</Helmet>
	);
};

Meta.defaultProps = {
	title: 'Cabsons Nutra Biohealth | Home of Quality Health Products',
	description:
		'Explore Cabsons Nutra Biohealth, your one-stop shop for quality health products. Browse our extensive range of supplements and health products, including Mens Health, Womens Health, Respiratory System, Metabolic System, Nervous System, Skeletal System, Endocrine System, Immune System, Cardiovascular System, and Anti-Aging products. Discover our latest deals and find the best products for your health and wellness needs.',
	keywords:
		'Cabsons Nutra Biohealth, Mens Health, Womens Health, Respiratory System, Metabolic System, Nervous System, Skeletal System, Endocrine System, Immune System, Cardiovascular System, Anti-Aging, Health Products, Supplements, Wellness, Online Shopping, Quality Products, Health and Wellness, Dietary Supplements, Herbal Supplements, Fitness Supplements, Natural Remedies, Health Solutions, Personal Care, Healthy Lifestyle, Nutritional Supplements, Vitamins, Minerals, Health Care, Body Care, Natural Health Products, Health Shop, Health Store, Health and Beauty, Health Brands, Health Retail, Health Market',
	image: `${window.location.origin}/images/cabsons-512.png`, // Default image URL
	url: '', // Default page URL
};

export default Meta;
