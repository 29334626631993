import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header';
import Footer from './components/Footer';
import FloatingButtons from './components/FloatingButtons';
import DevelopmentNotice from './components/DevelopmentNotice';
import ErrorBoundary from './components/ErrorBoundary';
// import { register } from './serviceWorkerRegistration';
// import OfflineRedirect from './components/OfflineRedirect';

const App = () => {
	const location = useLocation();

	useEffect(() => {
		window.gtag('config', 'G-J5J62GSFDY', {
			page_path: location.pathname + location.search,
		});
	}, [location]);

	// useEffect(() => {
	// 	register(); // Register the service worker
	// }, []);

	return (
		<>
			{/* <OfflineRedirect /> */}
			<Header />
			<main className='py-3' style={{ marginTop: '90px' }}>
				<ErrorBoundary>
					<Container>
						<DevelopmentNotice />
						<Outlet />
					</Container>
					<FloatingButtons />
				</ErrorBoundary>
			</main>

			<Footer />
			<ToastContainer />
		</>
	);
};

export default App;
