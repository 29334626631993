import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import FormContainer from '../components/FormContainer';
import Meta from '../components/Meta';
import Loader from '../components/Loader';
import {
	useLoginMutation,
	useRequestActivationMutation,
} from '../slices/usersApiSlice';
import { setCredentials } from '../slices/authSlice';
import { toast } from 'react-toastify';
import { Eye, EyeSlash } from 'react-bootstrap-icons';

// Login screen component
const LoginScreen = () => {
	// Local state for the email and password
	const [email, setEmail] = useState('');
	// Local state for the password
	const [password, setPassword] = useState('');

	// Local state for the password visibility
	const [showPassword, setShowPassword] = useState(false);

	// Request activation mutation from the users API slice
	const [requestActivation] = useRequestActivationMutation();

	// Dispatch function from the Redux store
	const dispatch = useDispatch();
	// Function to navigate to a different page
	const navigate = useNavigate();

	// Login mutation from the users API slice
	const [login, { isLoading }] = useLoginMutation();

	// Get the user info from the Redux store
	const { userInfo } = useSelector((state) => state.auth);

	// Get the current location from the URL
	const { search } = useLocation();
	// Get the redirect URL from the current location
	const sp = new URLSearchParams(search);
	//
	const redirect = sp.get('redirect') || '/';

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// Redirect to the previous page if the user is already logged in
	useEffect(() => {
		if (userInfo) {
			navigate(redirect);
		}
	}, [navigate, redirect, userInfo]);

	// Function to handle the request activation button click
	const handleRequestActivation = async () => {
		if (!email) {
			toast.error('Please enter your email to request activation', {
				autoClose: 10000,
			});
			return;
		}
		try {
			await requestActivation(email).unwrap();
			toast.success('Activation email sent');
		} catch (err) {
			if (
				err?.data?.message === 'TypeError: Failed to fetch' ||
				err.error === 'TypeError: Failed to fetch'
			) {
				toast.error(
					'You are currently offline. Please connect to the internet and try again.',
					{
						autoClose: 10000,
					},
				);
			} else if (err?.data?.message) {
				// Display the error message from the backend
				toast.error(err?.data?.message, {
					autoClose: 10000,
				});
			} else if (err.status === 400) {
				toast.error('Bad request. Please check your input and try again.', {
					autoClose: 10000,
				});
			} else if (err.status === 500) {
				toast.error('Internal server error. Please try again later.', {
					autoClose: 10000,
				});
			} else {
				toast.error(
					err?.data?.message ||
						err.error ||
						'An unexpected error occurred. Please try again.',
					{
						autoClose: 10000,
					},
				);
			}
		}
	};

	// Function to handle the form submit
	const submitHandler = async (e) => {
		e.preventDefault();
		try {
			// Call the login API endpoint and dispatch the setCredentials action if successful and navigate to the redirect URL
			const res = await login({ email, password }).unwrap();
			// Dispatch the setCredentials action with the user info
			dispatch(setCredentials({ ...res }));
			navigate(redirect);
			toast.success('Logged in successfully');
			// Track the event
			window.gtag('event', 'login', {
				event_category: 'User Interaction',
				event_label: 'Logged in',
			});
		} catch (err) {
			if (
				err?.data?.message === 'TypeError: Failed to fetch' ||
				err.error === 'TypeError: Failed to fetch'
			) {
				toast.error(
					'You are currently offline. Please connect to the internet and try again.',
				);
			} else if (err?.data?.message) {
				// Display the error message from the backend
				toast.error(err?.data?.message);
			} else if (err.status === 400) {
				toast.error('Bad request. Please check your input and try again.');
			} else if (err.status === 500) {
				toast.error('Internal server error. Please try again later.');
			} else {
				toast.error(
					err?.data?.message ||
						err.error ||
						'An unexpected error occurred. Please try again.',
				);
			}
		}
	};

	return (
		<>
			<Meta
				title='Login'
				description='Log in to your account to access your orders, wishlist, and more'
				keywords='login, user account'
				image={`${window.location.origin}/images/cabsons-512.png`}
			/>

			<FormContainer>
				<h1>Sign In</h1>

				<Form onSubmit={submitHandler}>
					<Form.Group controlId='email' className='my-3'>
						<Form.Label>Email Address</Form.Label>

						<Form.Control
							type='email'
							placeholder='Enter email'
							value={email}
							onChange={(e) => setEmail(e.target.value)}></Form.Control>
					</Form.Group>

					<Form.Group controlId='password' className='my-3'>
						<Form.Label>Password</Form.Label>

						<InputGroup>
							<Form.Control
								type={showPassword ? 'text' : 'password'}
								placeholder='Enter password'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<InputGroup.Text onClick={() => setShowPassword(!showPassword)}>
								{showPassword ? <EyeSlash /> : <Eye />}
							</InputGroup.Text>
						</InputGroup>
					</Form.Group>

					<Button
						type='submit'
						variant='primary'
						className='mt-2'
						disabled={isLoading}>
						Sign In
					</Button>

					{isLoading && <Loader />}
					<p className='mt-3'>
						Didn't receive an activation email?{' '}
						<Button
							variant='link'
							onClick={handleRequestActivation}
							disabled={isLoading}>
							Request Activation
						</Button>
					</p>
				</Form>
				<Row className='py-3'>
					<Col>
						New Customer?{' '}
						<Link
							to={redirect ? `/register?redirect=${redirect}` : '/register'}>
							Register
						</Link>
					</Col>
					<Col className='text-right'>
						<Link to='/password-reset-request'>Forgot Password?</Link>
					</Col>
				</Row>
			</FormContainer>
		</>
	);
};

export default LoginScreen;
