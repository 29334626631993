import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

// PrivateRoute component
const PrivateRoute = () => {
	// Get userInfo from redux store
	const { userInfo } = useSelector((state) => state.auth);

	// If userInfo is available, render Outlet (child routes), else redirect to login page
	return userInfo ? <Outlet /> : <Navigate to='/login' replace />;
};

export default PrivateRoute;
