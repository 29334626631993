// import React from 'react';
// import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import { FaWhatsapp, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';

// const FloatingButtons = () => {
// 	const handleClick = (eventCategory) => {
// 		window.gtag('event', 'contacting_method', {
// 			event_category: eventCategory,
// 			event_label: 'Floating Button',
// 		});
// 	};

// 	return (
// 		<div className='position-fixed bottom-0 end-0 p-3'>
// 			<ButtonGroup vertical>
// 				<OverlayTrigger
// 					placement='left'
// 					overlay={<Tooltip id='tooltip-whatsapp'>WhatsApp Chat</Tooltip>}>
// 					<Button
// 						variant='success'
// 						className='animate-blink whatsapp'
// 						href='https://wa.me/233240955284'
// 						target='_blank'
// 						style={{ marginBottom: '10px', animationDelay: '0s' }}
// 						onClick={() => handleClick('WhatsApp Chat')}>
// 						<FaWhatsapp />
// 					</Button>
// 				</OverlayTrigger>
// 				<OverlayTrigger
// 					placement='left'
// 					overlay={<Tooltip id='tooltip-call'>Phone Call</Tooltip>}>
// 					<Button
// 						variant='primary'
// 						href='tel:+233240955284'
// 						className='animate-blink'
// 						style={{ marginBottom: '10px', animationDelay: '0.5s' }}
// 						onClick={() => handleClick('Phone Call')}>
// 						<FaPhoneAlt style={{ marginRight: '5px' }} />
// 					</Button>
// 				</OverlayTrigger>
// 				<OverlayTrigger
// 					placement='left'
// 					overlay={<Tooltip id='tooltip-contact'>Contact Form</Tooltip>}>
// 					<Link
// 						to='/contact'
// 						className='btn btn-danger animate-blink'
// 						style={{ marginBottom: '70px', animationDelay: '1s' }}
// 						onClick={() => handleClick('Contact Form')}>
// 						<FaEnvelope />
// 					</Link>
// 				</OverlayTrigger>
// 			</ButtonGroup>
// 		</div>
// 	);
// };

// export default FloatingButtons;

import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaWhatsapp, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';

const FloatingButtons = () => {
	const handleClick = (eventCategory) => {
		window.gtag('event', 'contacting_method', {
			event_category: eventCategory,
			event_label: 'Floating Button',
		});
	};

	return (
		<div className='position-fixed bottom-0 end-0 p-3'>
			<ButtonGroup vertical>
				<Button
					variant='success'
					className='animate-blink hover-animate whatsapp'
					href='https://wa.me/233240955284'
					target='_blank'
					style={{ marginBottom: '10px', animationDelay: '0s' }}
					onClick={() => handleClick('WhatsApp Chat')}>
					<FaWhatsapp />
				</Button>
				<Button
					variant='primary'
					href='tel:+233240955284'
					className='animate-blink hover-animate'
					style={{ marginBottom: '10px', animationDelay: '0.5s' }}
					onClick={() => handleClick('Phone Call')}>
					<FaPhoneAlt style={{ marginRight: '5px' }} />
				</Button>
				<Link
					to='/contact'
					className='btn btn-danger animate-blink hover-animate'
					style={{ marginBottom: '70px', animationDelay: '1s' }}
					onClick={() => handleClick('Contact Form')}>
					<FaEnvelope />
				</Link>
			</ButtonGroup>
		</div>
	);
};

export default FloatingButtons;
