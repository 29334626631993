import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Rating from './Rating';
import { useEffect } from 'react';

// Product component
const Product = ({ product }) => {
	// Track product view
	useEffect(() => {
		window.gtag('event', 'view_product', {
			event_category: 'Product Interaction',
			event_label: `Viewed product ${product.name}`,
		});
	}, [product]);

	// Return product card
	return (
		// Product card STARTS
		<Card className='my-0 rounded hover-animate' title={product.name}>
			<Link to={`/product/${product._id}`} title={product.name}>
				<Card.Img src={product.images[0]} variant='top' />
			</Link>

			{/* Product details */}
			<Card.Body>
				{/* Product name */}
				<Link
					to={`/product/${product._id}`}
					title={product.name}
					style={{ textDecoration: 'none' }}>
					<Card.Title as='div' className='product-title'>
						{product.name}
					</Card.Title>
				</Link>

				{/* Product rating */}
				<Card.Text as='div'>
					<Rating
						value={product.rating}
						text={`${product.numReviews} reviews`}
					/>
				</Card.Text>

				{/* Product price */}
				<Card.Text as='h6' style={{ fontWeight: 'bold', color: 'black' }}>
					${product.price}
				</Card.Text>
			</Card.Body>
		</Card>
	);
};

export default Product;
