import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaTrash, FaChevronRight } from 'react-icons/fa';
import {
	useGetContactsQuery,
	useDeleteContactMutation,
} from '../../slices/contactApiSlice';
import { Table, Button } from 'react-bootstrap';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import Meta from '../../components/Meta';
import CTPaginate from '../../components/CTPaginate';

const ContactListScreen = () => {
	const { pageNumber } = useParams();
	const [currentPage, setCurrentPage] = useState(Number(pageNumber) || 1);
	const {
		data,
		isLoading: loading,
		error,
	} = useGetContactsQuery({
		pageNumber: currentPage,
		largePageSize: 20,
	});

	const navigate = useNavigate();

	const [deleteContact, { isLoading: deleting }] = useDeleteContactMutation();

	const deleteHandler = async (id) => {
		if (
			window.confirm('Are you sure you want to delete this contact/enquiry')
		) {
			await deleteContact(id);
		}
	};

	const replyHandler = (id) => {
		navigate(`/contact/${id}/reply`);
	};

	useEffect(() => {
		// Scroll to the top of the page
		window.scrollTo(0, 0);
	});

	return (
		<>
			<Meta title='List of Enquiries - Cabsons Nutra Biohealth' />
			<h1>Contact Form Submissions/Enquiries</h1>
			{loading ? (
				<Loader />
			) : error ? (
				error?.data?.message === 'TypeError: Failed to fetch' ||
				error.error === 'TypeError: Failed to fetch' ? (
					<Message variant='danger'>
						You are currently offline. Please connect to the internet and try
						again.
					</Message>
				) : (
					<Message variant='danger'>
						{error?.data?.message ||
							error.error ||
							'An error occurred, please try again'}
					</Message>
				)
			) : (
				<>
					<Table striped bordered hover responsive className='table-sm'>
						<thead>
							<tr>
								<th>ID</th>
								<th>NAME</th>
								<th>EMAIL</th>
								<th>MESSAGE</th>
								<th>DATE</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{data &&
								data.contacts.map((contact) => (
									<tr key={contact._id}>
										<td>{contact._id}</td>
										<td>{contact.name}</td>
										<td>
											<a href={`mailto:${contact.email}`}>{contact.email}</a>
										</td>
										<td>{contact.message}</td>
										<td>{contact.createdAt.substring(0, 10)}</td>
										<td>
											<Button
												variant='primary'
												className='btn-sm'
												onClick={() => replyHandler(contact._id)}>
												<FaChevronRight />
												Reply
											</Button>
										</td>
										<td>
											<Button
												variant='danger'
												className='btn-sm'
												onClick={() => deleteHandler(contact._id)}
												disabled={deleting}>
												<FaTrash style={{ color: 'white' }} />
											</Button>
										</td>
									</tr>
								))}
						</tbody>
					</Table>
					<CTPaginate
						pageNumber={currentPage}
						setPageNumber={setCurrentPage}
						data={data}
					/>
				</>
			)}
		</>
	);
};

export default ContactListScreen;
