import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button } from 'react-bootstrap';
import {
	FaTrash,
	FaEdit,
	FaCheck,
	FaTimes,
	FaChevronLeft,
} from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import {
	useGetUsersQuery,
	useDeleteUserMutation,
} from '../../slices/usersApiSlice';
import Meta from '../../components/Meta';
import CTPaginate from '../../components/CTPaginate';

const UserListScreen = () => {
	const { pageNumber } = useParams();
	const [currentPage, setCurrentPage] = useState(Number(pageNumber) || 1);
	const { data, isLoading, error, refetch } = useGetUsersQuery({
		pageNumber: currentPage,
		largePageSize: 20,
	});

	const [deleteUser] = useDeleteUserMutation();

	// Get the navigate function
	const navigate = useNavigate();

	// Function to handle the 'Go Back' button click
	const handleGoBack = () => {
		navigate(-1);
	};

	const deleteHandler = async (id) => {
		if (window.confirm('Are you sure you want to delete this user?')) {
			try {
				await deleteUser(id).unwrap();
				toast.success('User removed successfully');
				refetch();
			} catch (err) {
				if (
					err?.data?.message === 'TypeError: Failed to fetch' ||
					err.error === 'TypeError: Failed to fetch'
				) {
					toast.error(
						'You are currently offline. Please connect to the internet and try again.',
					);
				} else if (err?.data?.message) {
					// Display the error message from the backend
					toast.error(err?.data?.message);
				} else if (err.status === 400) {
					toast.error('Bad request. Please check your input and try again.');
				} else if (err.status === 500) {
					toast.error('Internal server error. Please try again later.');
				} else {
					toast.error(
						err?.data?.message ||
							err.error ||
							'An unexpected error occurred. Please try again.',
					);
				}
			}
		}
	};

	useEffect(() => {
		// Scroll to the top of the page
		window.scrollTo(0, 0);
	});

	return (
		<>
			<Meta title='All Users - Cabsons Nutra Biohealth' />
			<Button className='btn btn-light my-3 ' onClick={handleGoBack}>
				<FaChevronLeft />
				Previous Page
			</Button>
			<Link className='btn btn-light my-3 ms-3' to='/'>
				Home
			</Link>
			<h1>Users</h1>
			{isLoading ? (
				<Loader />
			) : error ? (
				error?.data?.message === 'TypeError: Failed to fetch' ||
				error.error === 'TypeError: Failed to fetch' ? (
					<Message variant='danger'>
						You are currently offline. User list is not available since you have
						not visited this page before while online. Please connect to the
						internet and try again.
					</Message>
				) : (
					<Message variant='danger'>
						{error?.data?.message ||
							error.error ||
							'An error occurred, please try again'}
					</Message>
				)
			) : (
				<>
					<Table striped bordered hover responsive className='table-lg'>
						<thead>
							<tr>
								<th>ID</th>
								<th>NAME</th>
								<th>EMAIL</th>
								<th>ADMIN</th>
								<th>VERIFIED</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{data &&
								data.users.map((user) => (
									<tr key={user._id}>
										<td>{user._id}</td>
										<td>{user.name}</td>
										<td>
											<a href={`mailto:${user.email}`}>{user.email}</a>
										</td>
										<td>
											{user.isAdmin ? (
												<FaCheck style={{ color: 'green' }} />
											) : (
												<FaTimes style={{ color: 'red' }} />
											)}
										</td>
										<td>
											{user.isEmailVerified ? (
												<FaCheck style={{ color: 'green' }} />
											) : (
												<FaTimes style={{ color: 'red' }} />
											)}
										</td>
										<td>
											<LinkContainer to={`/admin/user/${user._id}/edit`}>
												<Button variant='light' className='btn-sm'>
													<FaEdit />
												</Button>
											</LinkContainer>
											<Button
												variant='danger'
												className='btn-sm'
												onClick={() => deleteHandler(user._id)}>
												<FaTrash style={{ color: 'white' }} />
											</Button>
										</td>
									</tr>
								))}
						</tbody>
					</Table>
					{data && data.users && (
						<CTPaginate
							pageNumber={currentPage}
							setPageNumber={setCurrentPage}
							data={data}
						/>
					)}
				</>
			)}
		</>
	);
};

export default UserListScreen;
