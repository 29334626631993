import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useGetProductsQuery } from '../slices/productsApiSlice';
import Product from '../components/Product';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { Link } from 'react-router-dom';
import Meta from '../components/Meta';
import CTPaginate from '../components/CTPaginate';

const AllProductsScreen = () => {
	const [pageNumber, setPageNumber] = useState(1);
	const isMobile = window.innerWidth <= 768;

	const {
		data: productsData,
		isLoading: productsLoading,
		error: productsError,
	} = useGetProductsQuery(
		{
			pageNumber,
			isMobile,
			mobilePageSize: 8,
			largePageSize: 20,
		},
		{
			cacheTime: 0, // Disable caching
		},
	);

	// useEffect(() => {
	// 	if (
	// 		!navigator.onLine &&
	// 		(productsError
	// 			? productsError.error
	// 			: false || productsData
	// 			? productsData.error?.error
	// 			: false)
	// 	) {
	// 		window.location.href = '/offline.html';
	// 	}
	// }, [productsError, productsData]);

	useEffect(() => {
		// Scroll to the top of the page
		window.scrollTo(0, 0);
	});

	return (
		<>
			<Link className='btn btn-light my-3' to='/'>
				Home
			</Link>
			{productsLoading ? (
				<Loader />
			) : productsError ? (
				productsError?.data?.message === 'TypeError: Failed to fetch' ||
				productsError.error === 'TypeError: Failed to fetch' ? (
					<Message variant='danger'>
						You are currently offline. Product details are not available. Please
						connect to the internet and try again.
					</Message>
				) : (
					<Message variant='danger'>
						{productsError?.data?.message || productsError.error}
					</Message>
				)
			) : (
				<>
					<Meta
						title='All Products - Cabsons Nutra Biohealth'
						description='Explore Cabsons Nutra Biohealth, your one-stop shop for quality health products. Browse our extensive range of supplements and health products, including Mens Health, Womens Health, Respiratory System, Metabolic System, Nervous System, Skeletal System, Endocrine System, Immune System, Cardiovascular System, and Anti-Aging products. Discover our latest deals and find the best products for your health and wellness needs.'
						keywords='Cabsons Nutra Biohealth, Mens Health, Womens Health, Respiratory System, Metabolic System, Nervous System, Skeletal System, Endocrine System, Immune System, Cardiovascular System, Anti-Aging, Health Products, Supplements, Wellness, Online Shopping, Quality Products, Health and Wellness, Dietary Supplements, Herbal Supplements, Fitness Supplements, Natural Remedies, Health Solutions, Personal Care, Healthy Lifestyle, Nutritional Supplements, Vitamins, Minerals, Health Care, Body Care, Natural Health Products, Health Shop, Health Store, Health and Beauty, Health Brands, Health Retail, Health Market'
						image={`${window.location.origin}/images/cabsons-512.png`}
					/>

					<h1>All Products</h1>
					<Row>
						{productsData &&
							productsData.products &&
							productsData.products.map((product) => (
								<Col
									key={product._id}
									xs={6}
									sm={6}
									md={4}
									lg={3}
									xl={3}
									className='p-1'>
									<Product product={product} />
								</Col>
							))}
					</Row>
					{productsData && productsData.products && (
						<CTPaginate
							pageNumber={pageNumber}
							setPageNumber={setPageNumber}
							data={productsData}
						/>
					)}
				</>
			)}
		</>
	);
};

export default AllProductsScreen;
