import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FormContainer from '../components/FormContainer';
import Meta from '../components/Meta';
import { useSubmitContactFormMutation } from '../slices/contactApiSlice';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const ContactScreen = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');

	const [submitContactForm, { isLoading }] = useSubmitContactFormMutation();

	const navigate = useNavigate();

	const handleGoBack = () => {
		navigate(-1);
	};

	const submitHandler = async (e) => {
		e.preventDefault();
		try {
			const result = await submitContactForm({ name, email, message }).unwrap();
			toast.success(
				result.message ||
					'Thank you for your message. We will get back to you soon.',
			);
			// Track the event
			window.gtag('event', 'contact_form_submission', {
				event_category: 'User Interaction',
				event_label: 'Contact form submitted',
			});

			// Navigate to the home screen
			navigate('/');
		} catch (error) {
			if (
				error?.data?.message === 'TypeError: Failed to fetch' ||
				error.error === 'TypeError: Failed to fetch'
			) {
				toast.error(
					'You are currently offline. Please connect to the internet and try again.',
				);
			} else if (error?.data?.message) {
				// Display the error message from the backend
				toast.error(error?.data?.message);
			} else if (error.status === 400) {
				toast.error('Bad request. Please check your input and try again.');
			} else if (error.status === 500) {
				toast.error('Internal server error. Please try again later.');
			} else {
				toast.error(
					error.data?.message ||
						error.message ||
						'An unexpected error occurred. Please try again.',
				);
			}
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<Button className='btn btn-light my-3 ' onClick={handleGoBack}>
				<FaChevronLeft />
				Previous Page
			</Button>
			<Link className='btn btn-light my-3 ms-3' to='/'>
				Home
			</Link>
			<Meta
				title='Contact Us - Cabsons Nutra Biohealth'
				description='Get in touch with us by using the contact form. We will get back to you as soon as possible.'
				keywords='contact, email, message'
			/>
			<FormContainer>
				<h1>Contact Us</h1>
				{isLoading && <Loader />}
				<Form onSubmit={submitHandler}>
					<Form.Group controlId='name'>
						<Form.Label className='mt-3'>Name</Form.Label>
						<Form.Control
							type='text'
							placeholder='Enter name'
							value={name}
							onChange={(e) => setName(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group controlId='email'>
						<Form.Label className='mt-3'>Email Address</Form.Label>
						<Form.Control
							type='email'
							placeholder='Enter email'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group controlId='message'>
						<Form.Label className='mt-3'>Message</Form.Label>
						<Form.Control
							as='textarea'
							rows={3}
							placeholder='Enter message'
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							required
						/>
					</Form.Group>

					<Button
						className='mt-3'
						type='submit'
						variant='primary'
						disabled={isLoading}>
						{isLoading ? 'Submitting...' : 'Submit'}
					</Button>
				</Form>
			</FormContainer>
		</>
	);
};

export default ContactScreen;
