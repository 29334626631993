import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import FormContainer from '../components/FormContainer';
import Loader from '../components/Loader';
import { useRegisterMutation } from '../slices/usersApiSlice';
import { toast } from 'react-toastify';
import Meta from '../components/Meta';
import { Helmet } from 'react-helmet-async';
import { Eye, EyeSlash } from 'react-bootstrap-icons';

// Register screen component
const RegisterScreen = () => {
	// Local state for the form fields
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [passwordError, setPasswordError] = useState('');

	// Local state for the password visibility
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	// Function to navigate to a different page
	const navigate = useNavigate();

	// Register mutation from the users API slice
	const [register, { isLoading }] = useRegisterMutation();

	// Get the user info from the Redux store
	const { userInfo } = useSelector((state) => state.auth);

	// Get the redirect query parameter from the URL
	const { search } = useLocation();
	const sp = new URLSearchParams(search);
	const redirect = sp.get('redirect') || '/';

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// If the user is already logged in, redirect to the home page
	useEffect(() => {
		if (userInfo) {
			navigate(redirect);
		}
	}, [navigate, redirect, userInfo]);

	//
	const submitHandler = async (e) => {
		e.preventDefault();
		// Check if the passwords match and call the register API endpoint
		if (password !== confirmPassword) {
			toast.error('Passwords do not match');
			return;
		} else if (password.length < 8) {
			setPasswordError('Password should be at least 8 characters long');
			return;
		} else if (!/\d/.test(password)) {
			setPasswordError('Password should contain at least one number');
			return;
		} else if (!/[a-zA-Z]/.test(password)) {
			setPasswordError('Password should contain at least one letter');
			return;
		} else if (!/^[A-Za-z\d@$!%*#?&]*$/.test(password)) {
			setPasswordError('Password contains invalid characters');
			return;
		} else {
			try {
				await register({ name, email, password }).unwrap();
				toast.success('Registration successful.', {
					autoClose: 2000,
				});
				setTimeout(() => {
					toast.info(
						'A verification email has been sent to your email. Please verify your email to activate your account.',
						{
							autoClose: 10000,
						},
					);
				}, 2000);
				setTimeout(() => {
					toast.info(
						'If you do not see the email, please make sure the email you entered is correct.',
						{
							autoClose: 15000,
						},
					);
				}, 4000);
				// Track the event
				window.gtag('event', 'account_creation', {
					event_category: 'New User Interaction',
					event_label: 'Created an account',
				});
				navigate('/login');
			} catch (err) {
				if (
					err?.data?.message === 'TypeError: Failed to fetch' ||
					err.error === 'TypeError: Failed to fetch'
				) {
					toast.error(
						'You are currently offline. Please connect to the internet and try again.',
					);
				} else if (err?.data?.message) {
					// Display the error message from the backend
					toast.error(err?.data?.message);
				} else if (err.status === 400) {
					toast.error('Bad request. Please check your input and try again.');
				} else if (err.status === 500) {
					toast.error('Internal server error. Please try again later.');
				} else {
					toast.error(
						err?.data?.message ||
							err.error ||
							'An unexpected error occurred. Please try again.',
					);
				}
			}
		}
	};

	return (
		<>
			<Meta
				title='Register - Cabsons Nutra Biohealth'
				description='Create a new account to start shopping at Cabsons Nutra Biohealth'
				keywords='register, create account, new account'
				image={`${window.location.origin}/images/cabsons-512.png`}
			/>
			<Helmet>
				<script type='application/ld+json'>
					{JSON.stringify({
						'@context': 'https://schema.org',
						'@type': 'WebPage',
						url: window.location.href,
						name: 'Register',
						description: 'Create a new account to start shopping',
					})}
				</script>
			</Helmet>

			<FormContainer>
				<h1>Sign Up</h1>

				<Form onSubmit={submitHandler}>
					<Form.Group controlId='name' className='my-3'>
						<Form.Label>Name</Form.Label>

						<Form.Control
							type='text'
							placeholder='Enter your name'
							value={name}
							onChange={(e) => setName(e.target.value)}
							required></Form.Control>
					</Form.Group>

					<Form.Group controlId='email' className='my-3'>
						<Form.Label>Email Address</Form.Label>

						<Form.Control
							type='email'
							placeholder='Enter email'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required></Form.Control>
					</Form.Group>

					<Form.Group controlId='password' className='my-3'>
						<Form.Label>Password</Form.Label>

						<InputGroup>
							<Form.Control
								type={showPassword ? 'text' : 'password'}
								placeholder='Enter password'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<InputGroup.Text onClick={() => setShowPassword(!showPassword)}>
								{showPassword ? <EyeSlash /> : <Eye />}
							</InputGroup.Text>
						</InputGroup>
						<Form.Text className='text-muted'>
							Password should be at least 8 characters long and contain at least
							one letter and one number.
						</Form.Text>
						<br />
						{passwordError && (
							<Form.Text className='text-danger'>{passwordError}</Form.Text>
						)}
					</Form.Group>

					<Form.Group controlId='confirmPassword' className='my-3'>
						<Form.Label>Confirm Password</Form.Label>

						<InputGroup>
							<Form.Control
								type={showConfirmPassword ? 'text' : 'password'}
								placeholder='Confirm password'
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
							/>
							<InputGroup.Text
								onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
								{showConfirmPassword ? <EyeSlash /> : <Eye />}
							</InputGroup.Text>
						</InputGroup>
					</Form.Group>

					<Button
						type='submit'
						variant='primary'
						className='mt-2'
						disabled={isLoading}>
						Register
					</Button>

					{isLoading && <Loader />}
				</Form>
				<Row className='py-3'>
					<Col>
						Already have an account?{' '}
						<Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
							Login
						</Link>
					</Col>
				</Row>
			</FormContainer>
		</>
	);
};

export default RegisterScreen;
