// export const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : '';
export const BASE_URL = '';
export const PRODUCTS_URL = `/api/products`;
export const ORDERS_URL = `/api/orders`;
export const USERS_URL = `/api/users`;
export const PAYSTACK_PAY_URL = `/pay`;
export const PAYSTACK_VERIFY_URL = `/verify`;
export const UPLOAD_URL = `/api/uploads`;
export const CONTACT_URL = '/api/contact';
// export const PAYPAL_URL = `/api/config/paypal`;
