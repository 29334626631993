import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import assets from '../config.js';

const Footer = () => {
	const currentYear = new Date().getFullYear();
	const isMediumScreen = useMediaQuery({ minWidth: 768 });

	const rowStyle = {
		display: 'flex',
		flexDirection: isMediumScreen ? 'row' : 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
	};

	return (
		<footer style={footerStyle}>
			<Container>
				<Row style={rowStyle}>
					<Col xs={12} md={3} style={colStyle}>
						<Link to='/'>
							<img alt='logo' src={assets.logoDark} style={{ height: 50 }} />
						</Link>
					</Col>
					<Col xs={12} md={6} style={colStyle} className='text-center'>
						<small>
							Cabsons Nutra Biohealth &copy; {currentYear} All rights reserved.
						</small>
					</Col>
					<Col xs={12} md={3} style={colStyle}>
						<a
							href='https://www.facebook.com'
							target='_blank'
							rel='noopener noreferrer'
							style={linkStyle}>
							<FaFacebookF />
						</a>
						<a
							href='https://www.twitter.com'
							target='_blank'
							rel='noopener noreferrer'
							style={linkStyle}>
							<FaTwitter />
						</a>
						<a
							href='https://www.instagram.com'
							target='_blank'
							rel='noopener noreferrer'
							style={linkStyle}>
							<FaInstagram />
						</a>
					</Col>
				</Row>
			</Container>
		</footer>
	);
};

const footerStyle = {
	backgroundColor: '#f8f9fa',
	boxShadow: '0 -5px 20px rgba(0, 0, 0, 0.1)',
	padding: '20px 0',
};

const colStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	margin: '10px 0',
};

const linkStyle = {
	margin: '0 10px',
	color: '#333',
	textDecoration: 'none',
};

export default Footer;
