import { createSlice } from '@reduxjs/toolkit';
import { updateCart } from '../utils/cartUtils';

// Initial state of the cart slice
const initialState = localStorage.getItem('cart')
	? JSON.parse(localStorage.getItem('cart'))
	: { cartItems: [], shippingAddress: {}, paymentMethod: 'Paystack' };

// Cart Slice
const cartSlice = createSlice({
	name: 'cart', // Slice name
	initialState,
	reducers: {
		// Add to cart
		addToCart: (state, action) => {
			// Get the item to add to the cart
			const item = action.payload;
			// Check if the item is already in the cart
			const existItem = state.cartItems.find((x) => x._id === item._id);
			// If the item is already in the cart, then update the quantity
			if (existItem) {
				//
				state.cartItems = state.cartItems.map((x) =>
					x._id === existItem._id ? item : x,
				);
			} else {
				// If the item is not in the cart, then add it to the cart
				state.cartItems = [...state.cartItems, item];
			}
			return updateCart(state);
		},

		// Remove from cart
		removeFromCart: (state, action) => {
			// Filter out the item to remove from the cart
			state.cartItems = state.cartItems.filter((x) => x._id !== action.payload);
			// Update the prices and save to storage
			return updateCart(state);
		},

		// Save Shipping Address
		saveShippingAddress: (state, action) => {
			state.shippingAddress = action.payload;
			return updateCart(state);
		},

		// Save Payment Method
		savePaymentMethod: (state, action) => {
			state.paymentMethod = action.payload;
			localStorage.setItem('cart', JSON.stringify(state));
		},

		// Clear Cart Items
		clearCartItems: (state, action) => {
			state.cartItems = [];
			return updateCart(state);
			// localStorage.setItem('cart', JSON.stringify(state))
		},
		resetCart: (state) => (state = initialState),
	},
});

export const {
	addToCart,
	removeFromCart,
	saveShippingAddress,
	savePaymentMethod,
	clearCartItems,
	resetCart,
} = cartSlice.actions;

export default cartSlice.reducer;
