import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';

const DevelopmentNotice = () => {
	const [show, setShow] = useState(() => {
		const savedState = sessionStorage.getItem('devNoticeDismissed');
		return savedState ? JSON.parse(savedState) : true;
	});

	useEffect(() => {
		sessionStorage.setItem('devNoticeDismissed', JSON.stringify(show));
	}, [show]);

	if (!show) {
		return null;
	}

	return (
		<Alert
			variant='warning'
			onClose={() => setShow(false)}
			dismissible
			style={{ position: 'sticky', top: '90px', zIndex: '1000' }}>
			This site is still in development. The products are test products, payment
			is still using test payment and some features may not work as expected.
		</Alert>
	);
};

export default DevelopmentNotice;
