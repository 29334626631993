import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button } from 'react-bootstrap';
import { FaTimes, FaCheck } from 'react-icons/fa';
import { Link, useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import { useGetOrdersQuery } from '../../slices/ordersApiSlice';
import Meta from '../../components/Meta';
import CTPaginate from '../../components/CTPaginate';

const OrderListScreen = () => {
	const { pageNumber } = useParams();
	const [currentPage, setCurrentPage] = useState(Number(pageNumber) || 1);
	const { data, isLoading, error } = useGetOrdersQuery({
		pageNumber: currentPage,
		largePageSize: 20,
	});

	// Get the navigate function
	const navigate = useNavigate();

	// Function to handle the 'Go Back' button click
	const handleGoBack = () => {
		navigate(-1);
	};

	useEffect(() => {
		// Scroll to the top of the page
		window.scrollTo(0, 0);
	});

	return (
		<>
			<Meta title='All Orders - Cabsons Nutra Biohealth' />
			<Button className='btn btn-light my-3 ' onClick={handleGoBack}>
				<FaChevronLeft />
				Previous Page
			</Button>
			<Link className='btn btn-light my-3 ms-3' to='/'>
				Home
			</Link>
			<h1>Orders</h1>
			{isLoading ? (
				<Loader />
			) : error ? (
				error?.data?.message === 'TypeError: Failed to fetch' ||
				error.error === 'TypeError: Failed to fetch' ? (
					<Message variant='danger'>
						You are currently offline. Please connect to the internet and try
						again.
					</Message>
				) : (
					<Message variant='danger'>
						{error?.data?.message ||
							error.error ||
							'An error occurred, please try again'}
					</Message>
				)
			) : (
				<>
					<Table striped bordered hover responsive className='table-lg'>
						<thead>
							<tr>
								<th>ORDER ID</th>
								<th>USER</th>
								<th>DATE</th>
								<th>TOTAL</th>
								<th>PAID</th>
								<th>DELIVERED</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{data &&
								data.orders.map((order) => (
									<tr key={order._id}>
										<td>{order._id}</td>
										<td>{order.user && order.user.name}</td>
										<td>{order.createdAt.substring(0, 10)}</td>
										<td>${order.totalPrice}</td>
										<td>
											{order.isPaid ? (
												<>
													<FaCheck style={{ color: 'green' }} />
													{order.paidAt.substring(0, 10)}
												</>
											) : (
												<FaTimes style={{ color: 'red' }} />
											)}
										</td>
										<td>
											{order.isDelivered ? (
												<>
													<FaCheck style={{ color: 'green' }} />
													{order.deliveredAt.substring(0, 10)}
												</>
											) : (
												<FaTimes style={{ color: 'red' }} />
											)}
										</td>
										<td>
											<LinkContainer to={`/order/${order._id}`}>
												<Button variant='light' className='btn-sm'>
													Details
												</Button>
											</LinkContainer>
										</td>
									</tr>
								))}
						</tbody>
					</Table>
					{data && data.orders && (
						<CTPaginate
							pageNumber={currentPage}
							setPageNumber={setCurrentPage}
							data={data}
						/>
					)}
				</>
			)}
		</>
	);
};

export default OrderListScreen;
