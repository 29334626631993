import { useNavigate, useLocation } from 'react-router-dom';
import { Badge, Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { FaShoppingCart, FaUser } from 'react-icons/fa';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
// import { Link as ScrollLink } from 'react-scroll';
import React, { useState, useEffect } from 'react';
import { useLogoutMutation } from '../slices/usersApiSlice';
import { logout } from '../slices/authSlice';
import { useSelector, useDispatch } from 'react-redux';
import assets from '../config.js';
import SearchBox from './SearchBox';
import { resetCart } from '../slices/cartSlice';
import { toast } from 'react-toastify';

// New component that scrolls to the top of the page when it's clicked
const Logo = () => {
	const navigate = useNavigate();
	const handleClick = () => {
		navigate('/');
		window.scrollTo(0, 0);
	};
	return (
		<Navbar.Brand onClick={handleClick}>
			<img src={assets.logo} alt='cabsons Logo' style={{ height: 50 }} />
		</Navbar.Brand>
	);
};

// Header component
const Header = () => {
	// Get the cart items and user info from the Redux store
	const { cartItems } = useSelector((state) => state.cart);
	// Get the user info from the Redux store
	const { userInfo } = useSelector((state) => state.auth);

	// Dispatch function from the Redux store
	const dispatch = useDispatch();
	// Function to navigate to a different page
	const navigate = useNavigate();
	// Get the current location
	const location = useLocation();

	// State for the home page check
	const [isNecessary, setIsNecessary] = useState(location.pathname === '/');

	// State for the dropdown menu
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const [closeTimeout, setCloseTimeout] = useState(null);

	// Categories for the dropdown menu
	const categories = [
		[
			"Men's Health",
			"Women's Health",
			'Respiratory System',
			'Metabolic System',
		],
		['Nervous System', 'Skeletal System', 'Endocrine System', 'Immune System'],
		['Cardiovascular System', 'Anti-Aging'],
	];

	// Logout mutation from the users API slice
	const [logoutApiCall] = useLogoutMutation();

	// Function to handle the logout
	const logoutHandler = async () => {
		// Display confirmation dialog
		if (window.confirm('Are you sure you want to log out?')) {
			try {
				// Call the logout API endpoint and dispatch the logout action if successful and navigate to the login page
				await logoutApiCall().unwrap();
				dispatch(logout());
				dispatch(resetCart());
				navigate('/login');
			} catch (err) {
				// Display error message if the API call fails
				console.error(err);
				if (
					err?.data?.message === 'TypeError: Failed to fetch' ||
					err.error === 'TypeError: Failed to fetch'
				) {
					toast.error(
						'You are currently offline. Please connect to the internet and try again.',
					);
				} else if (err.status === 400) {
					toast.error('Bad request. Please check your input and try again.');
				} else if (err.status === 500) {
					toast.error('Internal server error. Please try again later.');
				} else {
					toast.error(
						err?.data?.message ||
							err.error ||
							'An unexpected error occurred. Please try again.',
					);
				}
			}
		}
	};

	// Prevent dropdown from disappearing due to the gap between the dropdown and the menu items
	const handleMouseEnter = () => {
		setIsDropdownOpen(true);
		if (closeTimeout) {
			clearTimeout(closeTimeout);
			setCloseTimeout(null);
		}
	};

	const handleMouseLeave = () => {
		setCloseTimeout(setTimeout(() => setIsDropdownOpen(false), 500)); // 500ms delay
	};

	// Dispatch the resetCart action when the user logs out
	useEffect(() => {
		if (!userInfo) {
			dispatch(resetCart());
		}
	}, [dispatch, userInfo]);

	// Effect to update isHomePage when the location changes
	useEffect(() => {
		setIsNecessary(
			location.pathname === '/' ||
				location.pathname.includes('/page') ||
				location.pathname.includes('/category') ||
				location.pathname.includes('/all-products') ||
				location.pathname.includes('/product'),
		);
	}, [location]);

	return (
		<header>
			{/* Navbar START */}
			<Navbar
				variant='dark'
				expand='lg'
				collapseOnSelect
				className='ct-navbar'
				fixed='top'>
				<Container>
					{/* Link for logo to go home */}
					{/* <LinkContainer to='/'>
						<Navbar.Brand>
							<img src={logo} alt='cabsons Logo' style={{ height: 50 }} />
						</Navbar.Brand>
					</LinkContainer> */}
					<Logo />
					<Navbar.Toggle aria-controls='basic-navbar-nav' />
					<Navbar.Collapse id='basic-navbar-nav'>
						{/* New Home and Electronics links */}
						{/* <Link to='/' className='nav-link ct-nav-link'>
								Home
							</Link> */}
						<Nav className='ms-auto'>
							{isNecessary && (
								<NavDropdown
									title='Categories'
									id='categories'
									className='multi-column-dropdown'
									show={isDropdownOpen}
									onMouseEnter={handleMouseEnter}
									onMouseLeave={handleMouseLeave}
									onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
									<div className='row'>
										{categories.map((column, i) => (
											<div className='col' key={i}>
												{column.map((category) => (
													<NavDropdown.Item
														as='div'
														key={category}
														className='dropdown-item-text'>
														<Link
															to={`/category/${encodeURIComponent(category)}`}
															className={`btn btn-link text-decoration-none text-start d-block ct-nav-dd-link`}
															style={{ padding: 0 }}>
															{category}
														</Link>
													</NavDropdown.Item>
												))}
											</div>
										))}
									</div>
								</NavDropdown>
							)}
						</Nav>
						{/* Link to Cart */}
						<Nav className='ms-auto'>
							<SearchBox />
							<LinkContainer to='/cart'>
								<Nav.Link href='/cart' className='ct-nav-link'>
									<FaShoppingCart /> Cart
									{cartItems.length > 0 && (
										<Badge pill bg='danger' style={{ marginLeft: '5px' }}>
											{cartItems.reduce((a, c) => a + c.qty, 0)}
										</Badge>
									)}
								</Nav.Link>
							</LinkContainer>
							{userInfo ? (
								<NavDropdown title={userInfo.name} id='username'>
									<LinkContainer to='/profile'>
										<NavDropdown.Item>Profile</NavDropdown.Item>
									</LinkContainer>
									<NavDropdown.Item onClick={logoutHandler}>
										Logout
									</NavDropdown.Item>
								</NavDropdown>
							) : (
								<LinkContainer to='/login'>
									<Nav.Link href='/login' className='ct-nav-link'>
										<FaUser /> Sign In
									</Nav.Link>
								</LinkContainer>
							)}
							{userInfo && userInfo.isAdmin && (
								<NavDropdown title='Admin Pages' id='adminmenu'>
									<LinkContainer to='/admin/productlist'>
										<NavDropdown.Item>Products</NavDropdown.Item>
									</LinkContainer>
									<LinkContainer to='/admin/userlist'>
										<NavDropdown.Item>Users</NavDropdown.Item>
									</LinkContainer>
									<LinkContainer to='/admin/orderlist'>
										<NavDropdown.Item>Orders</NavDropdown.Item>
									</LinkContainer>
									<LinkContainer to='/admin/contactlist'>
										<NavDropdown.Item>Enquiries</NavDropdown.Item>
									</LinkContainer>
								</NavDropdown>
							)}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			{/* Navbar END */}
		</header>
	);
};

export default Header;
