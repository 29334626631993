import { useEffect } from 'react';
import { useHasPurchasedQuery } from '../slices/ordersApiSlice';

export const useHasPurchased = (productId, userInfo) => {
	const { data: hasPurchased, refetch: refetchHasPurchased } =
		useHasPurchasedQuery(productId);

	useEffect(() => {
		if (userInfo) {
			refetchHasPurchased();
		}
	}, [refetchHasPurchased, userInfo]);

	return hasPurchased;
};
