import { createSlice } from '@reduxjs/toolkit';

// Initial state of the auth slice
const initialState = {
	// If user info is present in local storage, then set it as the initial state
	userInfo: localStorage.getItem('userInfo')
		? JSON.parse(localStorage.getItem('userInfo'))
		: null,
};

// Auth slice for redux store to manage user authentication
const authSlice = createSlice({
	name: 'auth', // Slice name
	initialState, // Initial state of the slice
	reducers: {
		// Set user credentials
		setCredentials: (state, action) => {
			// Set user info in the state and local storage
			state.userInfo = action.payload;
			// Set user info in local storage
			localStorage.setItem('userInfo', JSON.stringify(action.payload));
		},

		// Logout user
		logout: (state, action) => {
			state.userInfo = null;
			localStorage.clear();
		},
	},
});

// Export actions from the auth slice
export const { setCredentials, logout } = authSlice.actions;

// Export the auth slice reducer
export default authSlice.reducer;
