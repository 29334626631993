import { Pagination } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const Paginate = ({
	pages,
	page,
	isAdmin = false,
	keyword = '',
	category = '',
	onPageChange,
	route = '', // Add a new prop for the route
}) => {
	const baseUrl = isAdmin
		? keyword
			? `${route}/search/${keyword}/page`
			: `${route}/page` // Use the route prop if it's provided
		: keyword
		? `/search/${keyword}/page`
		: category
		? `/category/${category}`
		: '/page';

	return (
		pages > 1 && (
			<Pagination>
				{[...Array(pages).keys()].map((x) => (
					<LinkContainer
						key={x + 1}
						to={`${baseUrl}/${x + 1}`}
						onClick={() => onPageChange(x + 1)}>
						<Pagination.Item active={x + 1 === page}>{x + 1}</Pagination.Item>
					</LinkContainer>
				))}
			</Pagination>
		)
	);
};

export default Paginate;
