import { USERS_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const usersApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		// Define the API endpoints

		// Login endpoint
		login: builder.mutation({
			query: (data) => ({
				url: `${USERS_URL}/auth`,
				method: 'POST',
				body: data,
			}),
		}),

		// Register endpoint
		register: builder.mutation({
			query: (data) => ({
				url: `${USERS_URL}`,
				method: 'POST',
				body: data,
			}),
		}),

		// Email verification endpoint
		verifyEmail: builder.mutation({
			query: (token) => ({
				url: `${USERS_URL}/verify-email/${token}`,
				method: 'GET',
			}),
		}),

		// Request activation endpoint
		requestActivation: builder.mutation({
			query: (email) => ({
				url: `${USERS_URL}/request-activation`,
				method: 'POST',
				body: { email },
			}),
		}),

		// Logout endpoint
		logout: builder.mutation({
			query: () => ({
				url: `${USERS_URL}/logout`,
				method: 'POST',
			}),
		}),

		// Update user profile endpoint
		profile: builder.mutation({
			query: (data) => ({
				url: `${USERS_URL}/profile`,
				method: 'PUT',
				body: data,
			}),
		}),

		// Get all users endpoint
		getUsers: builder.query({
			query: (params) => ({
				url: USERS_URL,
				params,
			}),
			providesTags: ['Users'],
			keepUnusedDataFor: 5,
		}),

		// Delete user endpoint
		deleteUser: builder.mutation({
			query: (userId) => ({
				url: `${USERS_URL}/${userId}`,
				method: 'DELETE',
			}),
		}),

		// Get user details endpoint
		getUserDetails: builder.query({
			query: (id) => ({
				url: `${USERS_URL}/${id}`,
			}),
			keepUnusedDataFor: 5,
		}),

		// Update user endpoint
		updateUser: builder.mutation({
			query: (data) => ({
				url: `${USERS_URL}/${data.userId}`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['Users'],
		}),

		// Password reset request endpoint
		requestPasswordReset: builder.mutation({
			query: (data) => ({
				url: `${USERS_URL}/password-reset-request`,
				method: 'POST',
				body: data,
			}),
		}),

		// Password reset endpoint
		resetPassword: builder.mutation({
			query: ({ token, password }) => ({
				url: `${USERS_URL}/password-reset/${token}`,
				method: 'POST',
				body: { password },
			}),
		}),
	}),
});

export const {
	useLoginMutation,
	useLogoutMutation,
	useRegisterMutation,
	useVerifyEmailMutation,
	useRequestActivationMutation,
	useProfileMutation,
	useGetUsersQuery,
	useDeleteUserMutation,
	useGetUserDetailsQuery,
	useUpdateUserMutation,
	useRequestPasswordResetMutation,
	useResetPasswordMutation,
} = usersApiSlice;
