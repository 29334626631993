import { Col, Row } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Element } from 'react-scroll';
import { Helmet } from 'react-helmet-async';
import { FaChevronRight } from 'react-icons/fa';
import { useGetProductsQuery } from '../slices/productsApiSlice';
import Product from '../components/Product';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Paginate from '../components/Paginate';
import Meta from '../components/Meta';
import ProductCarousel from '../components/ProductCarousel';
import AboutUs from '../components/AboutUs';
import assets from '../config.js';

// import CTPaginate from '../components/CTPaginate';

// Home screen component
const HomeScreen = () => {
	// State variables for the page numbers
	// const [allProductsPageNumber, setAllProductsPageNumber] = useState(1);

	const [mensHealthPageNumber, setMensHealthPageNumber] = useState(1);
	const [womensHealthPageNumber, setWomensHealthPageNumber] = useState(1);
	const [respiratorySystemPageNumber, setRespiratorySystemPageNumber] =
		useState(1);
	const [metabolicSystemPageNumber, setMetabolicSystemPageNumber] = useState(1);
	const [nervousSystemPageNumber, setNervousSystemPageNumber] = useState(1);
	const [skeletalSystemPageNumber, setSkeletalSystemPageNumber] = useState(1);
	const [endocrineSystemPageNumber, setEndocrineSystemPageNumber] = useState(1);
	const [immuneSystemPageNumber, setImmuneSystemPageNumber] = useState(1);
	const [cardiovascularSystemPageNumber, setCardiovascularSystemPageNumber] =
		useState(1);
	const [antiAgingPageNumber, setAntiAgingPageNumber] = useState(1);

	const { keyword } = useParams();

	// State for the isMobile flag
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	// State for the isMedium flag
	const [isMedium, setIsMedium] = useState(
		window.innerWidth > 768 && window.innerWidth <= 991,
	);

	// Effect to update isMobile and isMedium when the window size changes
	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
			setIsMedium(window.innerWidth > 768 && window.innerWidth <= 991);
		};

		window.addEventListener('resize', handleResize);

		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	// Get all products from the Redux store
	const {
		data: allProductsData,
		isLoading: allProductsLoading,
		error: allProductsError,
	} = useGetProductsQuery(
		{
			keyword,
			// pageNumber: allProductsPageNumber,
			isMobile,
			isMedium,
		},
		{
			cacheTime: 0, // Disable caching
		},
	);

	// Get Men's Health products from the Redux store
	const {
		data: mensHealthProductsData,
		isLoading: mensHealthProductsLoading,
		error: mensHealthProductsError,
	} = useGetProductsQuery(
		{
			pageNumber: mensHealthPageNumber,
			category: "Men's Health",
			isMobile,
			largePageSize: 12,
		},
		{
			cacheTime: 0, // Disable caching
		},
	);

	// Get Women's Health products from the Redux store
	const {
		data: womensHealthProductsData,
		isLoading: womensHealthProductsLoading,
		error: womensHealthProductsError,
	} = useGetProductsQuery(
		{
			pageNumber: womensHealthPageNumber,
			category: "Women's Health",
			isMobile,
			largePageSize: 12,
		},
		{
			cacheTime: 0, // Disable caching
		},
	);

	// Get Respiratory System products from the Redux store
	const {
		data: respiratorySystemProductsData,
		isLoading: respiratorySystemProductsLoading,
		error: respiratorySystemProductsError,
	} = useGetProductsQuery(
		{
			pageNumber: respiratorySystemPageNumber,
			category: 'Respiratory System',
			isMobile,
			largePageSize: 12,
		},
		{
			cacheTime: 0, // Disable caching
		},
	);

	// Get Metabolic System products from the Redux store
	const {
		data: metabolicSystemProductsData,
		isLoading: metabolicSystemProductsLoading,
		error: metabolicSystemProductsError,
	} = useGetProductsQuery(
		{
			pageNumber: metabolicSystemPageNumber,
			category: 'Metabolic System',
			isMobile,
			largePageSize: 12,
		},
		{
			cacheTime: 0, // Disable caching
		},
	);

	// Get Nervous System products from the Redux store
	const {
		data: nervousSystemProductsData,
		isLoading: nervousSystemProductsLoading,
		error: nervousSystemProductsError,
	} = useGetProductsQuery(
		{
			pageNumber: nervousSystemPageNumber,
			category: 'Nervous System',
			isMobile,
			largePageSize: 12,
		},
		{
			cacheTime: 0, // Disable caching
		},
	);

	// Get Skeletal System products from the Redux store
	const {
		data: skeletalSystemProductsData,
		isLoading: skeletalSystemProductsLoading,
		error: skeletalSystemProductsError,
	} = useGetProductsQuery(
		{
			pageNumber: skeletalSystemPageNumber,
			category: 'Skeletal System',
			isMobile,
			largePageSize: 12,
		},
		{
			cacheTime: 0, // Disable caching
		},
	);

	// Get Endocrine System products from the Redux store
	const {
		data: endocrineSystemProductsData,
		isLoading: endocrineSystemProductsLoading,
		error: endocrineSystemProductsError,
	} = useGetProductsQuery(
		{
			pageNumber: endocrineSystemPageNumber,
			category: 'Endocrine System',
			isMobile,
			largePageSize: 12,
		},
		{
			cacheTime: 0, // Disable caching
		},
	);

	// Get Immune System products from the Redux store
	const {
		data: immuneSystemProductsData,
		isLoading: immuneSystemProductsLoading,
		error: immuneSystemProductsError,
	} = useGetProductsQuery(
		{
			pageNumber: immuneSystemPageNumber,
			category: 'Immune System',
			isMobile,
			largePageSize: 12,
		},
		{
			cacheTime: 0, // Disable caching
		},
	);

	// Get Cardiovascular System products from the Redux store
	const {
		data: cardiovascularSystemProductsData,
		isLoading: cardiovascularSystemProductsLoading,
		error: cardiovascularSystemProductsError,
	} = useGetProductsQuery(
		{
			pageNumber: cardiovascularSystemPageNumber,
			category: 'Cardiovascular System',
			isMobile,
			largePageSize: 12,
		},
		{
			cacheTime: 0, // Disable caching
		},
	);

	// Get Anti-Aging products from the Redux store
	const {
		data: antiAgingProductsData,
		isLoading: antiAgingProductsLoading,
		error: antiAgingProductsError,
	} = useGetProductsQuery(
		{
			pageNumber: antiAgingPageNumber,
			category: 'Anti-Aging',
			isMobile,
			largePageSize: 12,
		},
		{
			cacheTime: 0, // Disable caching
		},
	);

	// Define the categories
	const categories = [
		{
			name: "Men's Health",
			pageNumber: mensHealthPageNumber,
			setPageNumber: setMensHealthPageNumber,
			productsData: mensHealthProductsData,
			isLoading: mensHealthProductsLoading,
			error: mensHealthProductsError?.error,
			offlineError:
				mensHealthProductsError?.error?.data?.message ===
					'TypeError: Failed to fetch' ||
				mensHealthProductsError?.error?.error === 'TypeError: Failed to fetch',
		},
		{
			name: "Women's Health",
			pageNumber: womensHealthPageNumber,
			setPageNumber: setWomensHealthPageNumber,
			productsData: womensHealthProductsData,
			isLoading: womensHealthProductsLoading,
			error: womensHealthProductsError?.error,
			offlineError:
				womensHealthProductsError?.error?.data?.message ===
					'TypeError: Failed to fetch' ||
				womensHealthProductsError?.error?.error ===
					'TypeError: Failed to fetch',
		},
		{
			name: 'Respiratory System',
			pageNumber: respiratorySystemPageNumber,
			setPageNumber: setRespiratorySystemPageNumber,
			productsData: respiratorySystemProductsData,
			isLoading: respiratorySystemProductsLoading,
			error: respiratorySystemProductsError?.error,
			offlineError:
				respiratorySystemProductsError?.error?.data?.message ===
					'TypeError: Failed to fetch' ||
				respiratorySystemProductsError?.error?.error ===
					'TypeError: Failed to fetch',
		},
		{
			name: 'Metabolic System',
			pageNumber: metabolicSystemPageNumber,
			setPageNumber: setMetabolicSystemPageNumber,
			productsData: metabolicSystemProductsData,
			isLoading: metabolicSystemProductsLoading,
			error: metabolicSystemProductsError?.error,
			offlineError:
				metabolicSystemProductsError?.error?.data?.message ===
					'TypeError: Failed to fetch' ||
				metabolicSystemProductsError?.error?.error ===
					'TypeError: Failed to fetch',
		},
		{
			name: 'Nervous System',
			pageNumber: nervousSystemPageNumber,
			setPageNumber: setNervousSystemPageNumber,
			productsData: nervousSystemProductsData,
			isLoading: nervousSystemProductsLoading,
			error: nervousSystemProductsError?.error,
			offlineError:
				nervousSystemProductsError?.error?.data?.message ===
					'TypeError: Failed to fetch' ||
				nervousSystemProductsError?.error?.error ===
					'TypeError: Failed to fetch',
		},
		{
			name: 'Skeletal System',
			pageNumber: skeletalSystemPageNumber,
			setPageNumber: setSkeletalSystemPageNumber,
			productsData: skeletalSystemProductsData,
			isLoading: skeletalSystemProductsLoading,
			error: skeletalSystemProductsError?.error,
			offlineError:
				skeletalSystemProductsError?.error?.data?.message ===
					'TypeError: Failed to fetch' ||
				skeletalSystemProductsError?.error?.error ===
					'TypeError: Failed to fetch',
		},
		{
			name: 'Endocrine System',
			pageNumber: endocrineSystemPageNumber,
			setPageNumber: setEndocrineSystemPageNumber,
			productsData: endocrineSystemProductsData,
			isLoading: endocrineSystemProductsLoading,
			error: endocrineSystemProductsError?.error,
			offlineError:
				endocrineSystemProductsError?.error?.data?.message ===
					'TypeError: Failed to fetch' ||
				endocrineSystemProductsError?.error?.error ===
					'TypeError: Failed to fetch',
		},
		{
			name: 'Immune System',
			pageNumber: immuneSystemPageNumber,
			setPageNumber: setImmuneSystemPageNumber,
			productsData: immuneSystemProductsData,
			isLoading: immuneSystemProductsLoading,
			error: immuneSystemProductsError?.error,
			offlineError:
				immuneSystemProductsError?.error?.data?.message ===
					'TypeError: Failed to fetch' ||
				immuneSystemProductsError?.error?.error ===
					'TypeError: Failed to fetch',
		},
		{
			name: 'Cardiovascular System',
			pageNumber: cardiovascularSystemPageNumber,
			setPageNumber: setCardiovascularSystemPageNumber,
			productsData: cardiovascularSystemProductsData,
			isLoading: cardiovascularSystemProductsLoading,
			error: cardiovascularSystemProductsError?.error,
			offlineError:
				cardiovascularSystemProductsError?.error?.data?.message ===
					'TypeError: Failed to fetch' ||
				cardiovascularSystemProductsError?.error?.error ===
					'TypeError: Failed to fetch',
		},
		{
			name: 'Anti-Aging',
			pageNumber: antiAgingPageNumber,
			setPageNumber: setAntiAgingPageNumber,
			productsData: antiAgingProductsData,
			isLoading: antiAgingProductsLoading,
			error: antiAgingProductsError?.error,
			offlineError:
				antiAgingProductsError?.error?.data?.message ===
					'TypeError: Failed to fetch' ||
				antiAgingProductsError?.error?.error === 'TypeError: Failed to fetch',
		},
		// Add more categories here...
	];

	// Define your colors
	const colors = [
		'#1a3f8d', // Men
		'#b52483', // Women
		'#59ac38', // Respiratory
		'#e7bd29', // Metabolic
		'#632683', // Nervous
		'#737373', // Skeletal
		'#da92ba', // Endocrine
		'#5dc1bf', // Immune
		'#c30d24', // Cardiovascular
		'#bf912f', // Anti-aging
	];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// Track page view
	useEffect(() => {
		window.gtag('config', 'G-JZE5E7QFCB', {
			page_path: window.location.pathname + window.location.search,
		});
	}, []);

	// useEffect(() => {
	// 	if (
	// 		!navigator.onLine &&
	// 		(allProductsError?.error ||
	// 			allProductsData?.error?.error ||
	// 			mensHealthProductsError?.error ||
	// 			mensHealthProductsData?.error?.error ||
	// 			womensHealthProductsError?.error ||
	// 			womensHealthProductsData?.error?.error ||
	// 			respiratorySystemProductsError?.error ||
	// 			respiratorySystemProductsData?.error?.error ||
	// 			metabolicSystemProductsError?.error ||
	// 			metabolicSystemProductsData?.error?.error ||
	// 			nervousSystemProductsError?.error ||
	// 			nervousSystemProductsData?.error?.error ||
	// 			skeletalSystemProductsError?.error ||
	// 			skeletalSystemProductsData?.error?.error ||
	// 			endocrineSystemProductsError?.error ||
	// 			endocrineSystemProductsData?.error?.error ||
	// 			immuneSystemProductsError?.error ||
	// 			immuneSystemProductsData?.error?.error ||
	// 			cardiovascularSystemProductsError?.error ||
	// 			cardiovascularSystemProductsData?.error?.error ||
	// 			antiAgingProductsError?.error ||
	// 			antiAgingProductsData?.error?.error)
	// 	) {
	// 		window.location.href = '/offline.html';
	// 	}
	// }, [
	// 	allProductsError,
	// 	allProductsData,
	// 	mensHealthProductsError,
	// 	mensHealthProductsData,
	// 	womensHealthProductsError,
	// 	womensHealthProductsData,
	// 	respiratorySystemProductsError,
	// 	respiratorySystemProductsData,
	// 	metabolicSystemProductsError,
	// 	metabolicSystemProductsData,
	// 	nervousSystemProductsError,
	// 	nervousSystemProductsData,
	// 	skeletalSystemProductsError,
	// 	skeletalSystemProductsData,
	// 	endocrineSystemProductsError,
	// 	endocrineSystemProductsData,
	// 	immuneSystemProductsError,
	// 	immuneSystemProductsData,
	// 	cardiovascularSystemProductsError,
	// 	cardiovascularSystemProductsData,
	// 	antiAgingProductsError,
	// 	antiAgingProductsData,
	// ]);

	return (
		<>
			{!keyword ? (
				<ProductCarousel />
			) : (
				<Link to='/' className='btn btn-light mb-3'>
					Go Back
				</Link>
			)}
			{allProductsLoading ||
			mensHealthProductsLoading ||
			womensHealthProductsLoading ||
			respiratorySystemProductsLoading ||
			metabolicSystemProductsLoading ||
			nervousSystemProductsLoading ||
			skeletalSystemProductsLoading ||
			endocrineSystemProductsLoading ||
			immuneSystemProductsLoading ||
			cardiovascularSystemProductsLoading ||
			antiAgingProductsLoading ? (
				<Loader />
			) : allProductsError?.error ||
			  mensHealthProductsError?.error ||
			  womensHealthProductsError?.error ||
			  respiratorySystemProductsError?.error ||
			  metabolicSystemProductsError?.error ||
			  nervousSystemProductsError?.error ||
			  skeletalSystemProductsError?.error ||
			  endocrineSystemProductsError?.error ||
			  immuneSystemProductsError?.error ||
			  cardiovascularSystemProductsError?.error ||
			  antiAgingProductsError?.error ? (
				allProductsError?.data?.message === 'TypeError: Failed to fetch' ||
				allProductsError?.error === 'TypeError: Failed to fetch' ||
				mensHealthProductsError?.data?.message ===
					'TypeError: Failed to fetch' ||
				mensHealthProductsError?.error === 'TypeError: Failed to fetch' ||
				womensHealthProductsError?.data?.message ===
					'TypeError: Failed to fetch' ||
				womensHealthProductsError?.error === 'TypeError: Failed to fetch' ||
				respiratorySystemProductsError?.data?.message ===
					'TypeError: Failed to fetch' ||
				respiratorySystemProductsError?.error ===
					'TypeError: Failed to fetch' ||
				metabolicSystemProductsError?.data?.message ===
					'TypeError: Failed to fetch' ||
				metabolicSystemProductsError?.error === 'TypeError: Failed to fetch' ||
				nervousSystemProductsError?.data?.message ===
					'TypeError: Failed to fetch' ||
				nervousSystemProductsError?.error === 'TypeError: Failed to fetch' ||
				skeletalSystemProductsError?.data?.message ===
					'TypeError: Failed to fetch' ||
				skeletalSystemProductsError?.error === 'TypeError: Failed to fetch' ||
				endocrineSystemProductsError?.data?.message ===
					'TypeError: Failed to fetch' ||
				endocrineSystemProductsError?.error === 'TypeError: Failed to fetch' ||
				immuneSystemProductsError?.data?.message ===
					'TypeError: Failed to fetch' ||
				immuneSystemProductsError?.error === 'TypeError: Failed to fetch' ||
				cardiovascularSystemProductsError?.data?.message ===
					'TypeError: Failed to fetch' ||
				cardiovascularSystemProductsError?.error ===
					'TypeError: Failed to fetch' ||
				antiAgingProductsError?.data?.message ===
					'TypeError: Failed to fetch' ||
				antiAgingProductsError?.error === 'TypeError: Failed to fetch' ? (
					<Message variant='danger'>
						You are currently offline. Product details are not available. Please
						connect to the internet and try again.
					</Message>
				) : (
					<Message variant='danger'>
						{allProductsError?.data?.message ||
							allProductsError?.error ||
							mensHealthProductsError?.data?.message ||
							mensHealthProductsError?.error ||
							womensHealthProductsError?.data?.message ||
							womensHealthProductsError?.error ||
							respiratorySystemProductsError?.data?.message ||
							respiratorySystemProductsError?.error ||
							metabolicSystemProductsError?.data?.message ||
							metabolicSystemProductsError?.error ||
							nervousSystemProductsError?.data?.message ||
							nervousSystemProductsError?.error ||
							skeletalSystemProductsError?.data?.message ||
							skeletalSystemProductsError?.error ||
							endocrineSystemProductsError?.data?.message ||
							endocrineSystemProductsError?.error ||
							immuneSystemProductsError?.data?.message ||
							immuneSystemProductsError?.error ||
							cardiovascularSystemProductsError?.data?.message ||
							cardiovascularSystemProductsError?.error ||
							antiAgingProductsError?.data?.message ||
							antiAgingProductsError?.error}
					</Message>
				)
			) : (
				<>
					<Meta
						title='Cabsons Nutra Biohealth | Home of Quality Health Products'
						description='Explore Cabsons Nutra Biohealth, your one-stop shop for quality health products. Browse our extensive range of supplements and health products, including Mens Health, Womens Health, Respiratory System, Metabolic System, Nervous System, Skeletal System, Endocrine System, Immune System, Cardiovascular System, and Anti-Aging products. Discover our latest deals and find the best products for your health and wellness needs.'
						keywords='Cabsons Nutra Biohealth, Mens Health, Womens Health, Respiratory System, Metabolic System, Nervous System, Skeletal System, Endocrine System, Immune System, Cardiovascular System, Anti-Aging, Health Products, Supplements, Wellness, Online Shopping, Quality Products, Health and Wellness, Dietary Supplements, Herbal Supplements, Fitness Supplements, Natural Remedies, Health Solutions, Personal Care, Healthy Lifestyle, Nutritional Supplements, Vitamins, Minerals, Health Care, Body Care, Natural Health Products, Health Shop, Health Store, Health and Beauty, Health Brands, Health Retail, Health Market'
						image={`${window.location.origin}/images/cabsons-512.png`}
					/>
					<Helmet>
						<script type='application/ld+json'>
							{JSON.stringify({
								'@context': 'https://schema.org',
								'@type': 'WebSite',
								url: window.location.origin,
								potentialAction: {
									'@type': 'SearchAction',
									target: `${window.location.origin}/search?q={search_term_string}`,
									'query-input': 'required name=search_term_string',
								},
							})}
						</script>
					</Helmet>

					{/* Handle "All Products" separately */}
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
						className='mt-5'>
						<h2 className='category-header'>Latest Products</h2>
						<Link
							to='/all-products'
							className='btn btn-link text-decoration-none text-end d-block'>
							See All <FaChevronRight />
						</Link>
					</div>
					<Row>
						{allProductsLoading ? (
							<Loader />
						) : allProductsError ? (
							allProductsError?.data?.message ===
								'TypeError: Failed to fetch' ||
							allProductsError.error === 'TypeError: Failed to fetch' ? (
								<Message variant='danger'>
									You are currently offline. Product details are not available
									since you have not visited this page before while online.
									Please connect to the internet and try again.
								</Message>
							) : (
								<Message variant='danger'>{allProductsError}</Message>
							)
						) : allProductsData?.products?.length > 0 ? (
							allProductsData.products.map((product) => (
								<Col
									key={product._id}
									xs={6}
									sm={6}
									md={4}
									lg={3}
									xl={3}
									className='p-1'>
									<Product product={product} />
								</Col>
							))
						) : (
							<Message>No products available now</Message>
						)}
					</Row>
					<Link to='/all-products' className='btn btn-light mt-3'>
						See All Products
					</Link>
					{/* <CTPaginate
						pageNumber={allProductsPageNumber}
						setPageNumber={setAllProductsPageNumber}
						data={allProductsData}
					/> */}

					{categories.map((category, index) => (
						<Element id={category.name.toLowerCase()} key={category.name}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									backgroundColor: colors[index % colors.length], // Use the index to select a color
								}}
								className='mt-5 mb-2 text-light'>
								<h2 className='category-header'>{category.name}</h2>
								<Link
									to={`/category/${encodeURIComponent(category.name)}`}
									className='btn btn-link text-decoration-none text-end d-block text-light'>
									See All <FaChevronRight />
								</Link>
							</div>
							<img
								src={
									assets[
										category.name
											.replace(/\s+/g, '')
											.replace(/'/g, '')
											.replace(/-/g, '')
											.toLowerCase()
									]
								}
								alt={category.name}
								style={{ width: '100%', height: 'auto' }}
							/>
							<Row>
								{category.isLoading ? (
									<Loader />
								) : category.error ? (
									category.offlineError ? (
										<Message variant='danger'>
											You are currently offline. Product details are not
											available since you have not visited this page before
											while online. Please connect to the internet and try
											again.
										</Message>
									) : (
										<Message variant='danger'>{category.error}</Message>
									)
								) : category.productsData?.products?.length > 0 ? (
									category.productsData.products.map((product) => (
										<Col
											key={product._id}
											xs={6}
											sm={6}
											md={4}
											lg={3}
											xl={3}
											className='p-1'>
											<Product product={product} />
										</Col>
									))
								) : (
									<Message>No products available in this category now</Message>
								)}
							</Row>
							<Paginate
								pages={
									category && category.productsData
										? category.productsData.pages
										: 0
								}
								page={category ? category.pageNumber : 1}
								category={category ? category.name : ''}
								onPageChange={category ? category.setPageNumber : () => {}}
							/>
						</Element>
					))}
					<AboutUs />
				</>
			)}
		</>
	);
};

export default HomeScreen;
