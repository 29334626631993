import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Row, Col, ListGroup, Image, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import CheckoutSteps from '../components/CheckoutSteps';
import Loader from '../components/Loader';
import { useCreateOrderMutation } from '../slices/ordersApiSlice';
import { clearCartItems } from '../slices/cartSlice';
import Meta from '../components/Meta';

// Place order screen component
const PlaceOrderScreen = () => {
	// Function to navigate to a different page
	const navigate = useNavigate();
	// Dispatch function from the Redux store
	const dispatch = useDispatch();

	// Get the cart items from the Redux store
	const cart = useSelector((state) => state.cart);

	// Create Order mutation from the orders API slice
	const [createOrder, { isLoading, error }] = useCreateOrderMutation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// If the shipping address or payment method is not set, redirect to the shipping or payment screen
	useEffect(() => {
		if (!cart.shippingAddress.address) {
			navigate('/shipping');
		} else if (!cart.paymentMethod) {
			navigate('/payment');
		}
	}, [cart.paymentMethod, cart.shippingAddress.address, navigate]);

	// Function to handle the place order button click
	const placeOrderHandler = async () => {
		try {
			// Call the create order API endpoint and dispatch the clearCartItems action if successful and navigate to the order details page
			const res = await createOrder({
				orderItems: cart.cartItems,
				shippingAddress: cart.shippingAddress,
				paymentMethod: cart.paymentMethod,
				itemsPrice: cart.itemsPrice,
				shippingPrice: cart.shippingPrice,
				// taxPrice: cart.taxPrice,
				totalPrice: cart.totalPrice,
			}).unwrap();
			dispatch(clearCartItems());
			navigate(`/order/${res._id}`);
			// Track the event
			window.gtag('event', 'place_order', {
				event_category: 'User Interaction',
				event_label: 'Placed an order',
			});
		} catch (err) {
			if (
				err?.data?.message === 'TypeError: Failed to fetch' ||
				err.error === 'TypeError: Failed to fetch'
			) {
				toast.error(
					'You are currently offline. Please connect to the internet and try again.',
				);
			} else if (err?.data?.message) {
				// Display the error message from the backend
				toast.error(err?.data?.message);
			} else if (err.status === 400) {
				toast.error('Bad request. Please check your input and try again.');
			} else if (err.status === 500) {
				toast.error('Internal server error. Please try again later.');
			} else {
				toast.error(
					err?.data?.message ||
						err.error ||
						'An unexpected error occurred. Please try again.',
				);
			}
		}
	};

	return (
		<>
			<Meta
				title='Place Order - Cabsons Nutra Biohealth'
				description='Review your order details and place your order'
				keywords='place order, order details, review order'
				image={`${window.location.origin}/images/cabsons-512.png`}
			/>
			<CheckoutSteps step1 step2 step3 step4 />
			<Row>
				<Col md={8}>
					<ListGroup variant='flush'>
						<ListGroup.Item>
							<h2>Shipping</h2>
							<p>
								<strong>Address: </strong>
								{cart.shippingAddress.address}, {cart.shippingAddress.city}{' '}
								{cart.shippingAddress.postalCode},{' '}
								{cart.shippingAddress.country}
							</p>
							<p>
								<strong>Phone Number: </strong>
								{cart.shippingAddress.phoneNumber}
							</p>
						</ListGroup.Item>

						<ListGroup.Item>
							<h2>Payment Method</h2>
							<strong>Method: </strong>
							{cart.paymentMethod}
						</ListGroup.Item>

						<ListGroup.Item>
							<h2>Order Items</h2>
							{cart.cartItems.length === 0 ? (
								<Message>Your cart is empty</Message>
							) : (
								<ListGroup variant='flush'>
									{cart.cartItems.map((item, index) => (
										<ListGroup.Item key={index}>
											<Row>
												<Col md={1}>
													<Image
														src={item.images[0]}
														alt={item.name}
														fluid
														rounded
													/>
												</Col>
												<Col>
													<Link to={`/product/${item.product}`}>
														{item.name}
													</Link>
												</Col>
												<Col md={4}>
													{item.qty} x ${item.price} = ${' '}
													{(item.qty * (item.price * 100)) / 100}
												</Col>
											</Row>
										</ListGroup.Item>
									))}
								</ListGroup>
							)}
						</ListGroup.Item>
					</ListGroup>
				</Col>
				<Col md={4}>
					<Card>
						<ListGroup variant='flush'>
							<ListGroup.Item>
								<h2>Order Summary</h2>
							</ListGroup.Item>
							<ListGroup.Item>
								<Row>
									<Col>Item(s) Price</Col>
									<Col>${cart.itemsPrice}</Col>
								</Row>
							</ListGroup.Item>
							<ListGroup.Item>
								<Row>
									<Col>Shipping</Col>
									<Col>${cart.shippingPrice}</Col>
								</Row>
							</ListGroup.Item>
							{/* <ListGroup.Item>
								<Row>
									<Col>Tax</Col>
									<Col>${cart.taxPrice}</Col>
								</Row>
							</ListGroup.Item> */}
							<ListGroup.Item>
								<Row>
									<Col>Total</Col>
									<Col>${cart.totalPrice}</Col>
								</Row>
							</ListGroup.Item>
							<ListGroup.Item>
								{error ? (
									error?.data?.message === 'TypeError: Failed to fetch' ||
									error.error === 'TypeError: Failed to fetch' ? (
										<>
											<Message variant='danger'>
												You are currently offline. Please connect to the
												internet and try again.
											</Message>
										</>
									) : (
										<>
											<Message variant='danger'>
												{error?.data?.message || error.error}
											</Message>
											<Button
												type='button'
												className='btn-block'
												disabled={cart.cartItems === 0}
												onClick={placeOrderHandler}>
												Place Order
											</Button>
										</>
									)
								) : (
									<Button
										type='button'
										className='btn-block'
										disabled={cart.cartItems === 0}
										onClick={placeOrderHandler}>
										Place Order
									</Button>
								)}
								{isLoading && <Loader />}
							</ListGroup.Item>
						</ListGroup>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default PlaceOrderScreen;
