// config.js
const isProduction = process.env.NODE_ENV === 'production';

const STATIC_URL = isProduction
	? 'https://cabsons-images-production.s3.eu-north-1.amazonaws.com/static/'
	: '/';

const assets = {
	logo: `${STATIC_URL}${isProduction ? 'logo.png' : 'logo.png'}`,
	logoDark: `${STATIC_URL}${isProduction ? 'logo-dark.png' : 'logo-dark.png'}`,
	oops: `${STATIC_URL}${isProduction ? 'oops.svg' : 'oops.svg'}`,
	cabsonsLogoDark: `${STATIC_URL}${
		isProduction ? 'CabsonsLogoDark.png' : 'CabsonsLogoDark.png'
	}`,
	pageNotFound: `${STATIC_URL}${
		isProduction ? 'page-not-found.svg' : 'page-not-found.svg'
	}`,
	fruit: `${STATIC_URL}${isProduction ? 'fruits2.jpeg' : 'fruits2.jpeg'}`,
	menshealth: `${STATIC_URL}${
		isProduction ? 'menshealth.jpg' : 'menshealth.jpg'
	}`,
	womenshealth: `${STATIC_URL}${
		isProduction ? 'womenshealth.jpg' : 'womenshealth.jpg'
	}`,
	respiratorysystem: `${STATIC_URL}${
		isProduction ? 'respiratorysystem.jpg' : 'respiratorysystem.jpg'
	}`,
	metabolicsystem: `${STATIC_URL}${
		isProduction ? 'metabolicsystem.jpg' : 'metabolicsystem.jpg'
	}`,
	nervoussystem: `${STATIC_URL}${
		isProduction ? 'nervoussystem.jpg' : 'nervoussystem.jpg'
	}`,
	skeletalsystem: `${STATIC_URL}${
		isProduction ? 'skeletalsystem.jpg' : 'skeletalsystem.jpg'
	}`,
	endocrinesystem: `${STATIC_URL}${
		isProduction ? 'endocrinesystem.jpg' : 'endocrinesystem.jpg'
	}`,
	immunesystem: `${STATIC_URL}${
		isProduction ? 'immunesystem.jpg' : 'immunesystem.jpg'
	}`,
	cardiovascularsystem: `${STATIC_URL}${
		isProduction ? 'cardiovascularsystem.jpg' : 'cardiovascularsystem.jpg'
	}`,
	antiaging: `${STATIC_URL}${
		isProduction ? 'anti-aging.jpg' : 'anti-aging.jpg'
	}`,
};

export default assets;
