import React, { useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRequestPasswordResetMutation } from '../slices/usersApiSlice';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

const PasswordResetRequestScreen = () => {
	const navigate = useNavigate();
	const [requestPasswordReset, { isLoading, isError, error, isSuccess }] =
		useRequestPasswordResetMutation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (isError) {
			if (
				error?.data?.message === 'TypeError: Failed to fetch' ||
				error.error === 'TypeError: Failed to fetch'
			) {
				toast.error(
					'You are currently offline. Please connect to the internet and try again.',
				);
			} else if (error?.data?.message) {
				// Display the error message from the backend
				toast.error(error?.data?.message);
			} else if (error.status === 400) {
				toast.error('Bad request. Please check your input and try again.');
			} else if (error.status === 500) {
				toast.error('Internal server error. Please try again later.');
			} else {
				toast.error(
					error?.data?.message ||
						error.message ||
						'An unexpected error occurred. Please try again',
				);
			}
		}
		if (isSuccess) {
			toast.success('Password reset email sent. Please check your inbox.');
			navigate('/login');
		}
	}, [isError, isSuccess, error, navigate]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		const email = event.target.elements.email.value;
		await requestPasswordReset({ email });
		// Track the event
		window.gtag('event', 'request_password_reset', {
			event_category: 'User Interaction',
			event_label: 'Requested password reset',
		});
	};

	return (
		<>
			<Helmet>
				<title>Password Reset Request - Cabsons Nutra Biohealth</title>
				<meta name='description' content='Request a password reset email' />
			</Helmet>

			<FormContainer>
				<h1>Password Reset Request</h1>
				<Form onSubmit={handleSubmit}>
					<Form.Group controlId='email' className='my-3'>
						<Form.Label>Email Address</Form.Label>
						<Form.Control
							type='email'
							placeholder='Enter email'
							required></Form.Control>
					</Form.Group>

					<Button
						type='submit'
						variant='primary'
						className='mt-2'
						disabled={isLoading}>
						Submit
					</Button>

					{isLoading && <Loader />}
				</Form>
			</FormContainer>
		</>
	);
};

export default PasswordResetRequestScreen;
