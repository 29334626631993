import { useState, useEffect } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormContainer from '../components/FormContainer';
import CheckoutSteps from '../components/CheckoutSteps';
import { savePaymentMethod } from '../slices/cartSlice';
import Meta from '../components/Meta';

// Payment screen component
const PaymentScreen = () => {
	// Local state for the payment method
	const [paymentMethod, setPaymentMethod] = useState('Paystack');

	// Dispatch function from the Redux store
	const dispatch = useDispatch();
	// Function to navigate to a different page
	const navigate = useNavigate();

	// Get the shipping address from the Redux store
	const cart = useSelector((state) => state.cart);
	// Destructure the shipping address from the cart
	const { shippingAddress } = cart;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// If the shipping address is not set, redirect to the shipping screen
	useEffect(() => {
		if (!shippingAddress.address) {
			navigate('/shipping');
		}
	}, [navigate, shippingAddress]);

	// Function to handle the form submit
	const submitHandler = (e) => {
		e.preventDefault();
		dispatch(savePaymentMethod(paymentMethod));
		navigate('/placeorder');
		// Track the event
		window.gtag('event', 'select_payment_method', {
			event_category: 'User Interaction',
			event_label: `Selected payment method: ${paymentMethod}`,
		});
	};

	return (
		<>
			<Meta
				title='Payment Method - Cabsons Nutra Biohealth'
				description='Select your preferred payment method and proceed to place your order'
				keywords='payment method, place order'
				image={`${window.location.origin}/images/cabsons-512.png`}
			/>
			<FormContainer>
				<CheckoutSteps step1 step2 step3 />
				<h1>Payment Method</h1>
				<Form onSubmit={submitHandler}>
					<Form.Group>
						<Form.Label as='legend'>Select Method</Form.Label>
						<Col>
							<Form.Check
								className='my-2'
								type='radio'
								label='Paystack (Card or Mobile Money)'
								id='Paystack'
								name='paymentMethod'
								value='Paystack'
								checked
								onChange={(e) => setPaymentMethod(e.target.value)}></Form.Check>
						</Col>
					</Form.Group>

					<Button type='submit' variant='primary'>
						Continue
					</Button>
				</Form>
			</FormContainer>
		</>
	);
};

export default PaymentScreen;
