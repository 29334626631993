import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FormContainer from '../../components/FormContainer';
import Meta from '../../components/Meta';
import {
	useGetContactByIdQuery,
	useReplyContactMutation,
} from '../../slices/contactApiSlice';
import Loader from '../../components/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import Message from '../../components/Message';

const ReplyScreen = () => {
	const [reply, setReply] = useState('');

	const { id } = useParams();
	const { data: contact, isLoading: loadingContact } =
		useGetContactByIdQuery(id);
	const [replyContact, { isLoading: loadingReply }] = useReplyContactMutation();

	const navigate = useNavigate();

	const submitHandler = async (e) => {
		e.preventDefault();
		try {
			const result = await replyContact({ id, reply }).unwrap();
			toast.success(result.message || 'Reply sent successfully.');

			// Navigate to the contact list screen
			navigate('/admin/contactlist');
		} catch (err) {
			if (
				err?.data?.message === 'TypeError: Failed to fetch' ||
				err.error === 'TypeError: Failed to fetch'
			) {
				toast.error(
					'You are currently offline. Please connect to the internet and try again.',
				);
			} else if (err?.data?.message) {
				// Display the error message from the backend
				toast.error(err?.data?.message);
			} else if (err.status === 400) {
				toast.error('Bad request. Please check your input and try again.');
			} else if (err.status === 500) {
				toast.error('Internal server error. Please try again later.');
			} else {
				toast.error(
					err?.data?.message ||
						err.error ||
						'An unexpected error occurred. Please try again.',
				);
			}
		}
	};

	return (
		<>
			<Meta title='Reply to Contact Form Submission' />
			<FormContainer>
				<h1>Reply to Contact Form Submission</h1>
				{loadingContact ? (
					<Loader />
				) : contact ? (
					<Form onSubmit={submitHandler}>
						<Form.Group controlId='name'>
							<Form.Label className='mt-3'>Name</Form.Label>
							<Form.Control type='text' value={contact.name} readOnly />
						</Form.Group>

						<Form.Group controlId='email'>
							<Form.Label className='mt-3'>Email Address</Form.Label>
							<Form.Control type='email' value={contact.email} readOnly />
						</Form.Group>

						<Form.Group controlId='message'>
							<Form.Label className='mt-3'>Message</Form.Label>
							<Form.Control
								as='textarea'
								rows={3}
								value={contact.message}
								readOnly
							/>
						</Form.Group>

						<Form.Group controlId='reply'>
							<Form.Label className='mt-3'>Reply</Form.Label>
							<Form.Control
								as='textarea'
								rows={3}
								placeholder='Enter reply'
								value={reply}
								onChange={(e) => setReply(e.target.value)}
								required
							/>
						</Form.Group>

						<Button
							className='mt-3'
							type='submit'
							variant='primary'
							disabled={loadingReply}>
							{loadingReply ? 'Sending...' : 'Send'}
						</Button>
					</Form>
				) : !navigator.onLine ? (
					<Message variant='danger'>
						You are currently offline. Please connect to the internet and try
						again.
					</Message>
				) : (
					<Message variant='danger'>Contact not found</Message>
				)}
			</FormContainer>
		</>
	);
};

export default ReplyScreen;
