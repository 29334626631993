// Desc: Admin product edit screen to allow the admin to edit a product in the system
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Row, Col, Image } from 'react-bootstrap';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import FormContainer from '../../components/FormContainer';
import { toast } from 'react-toastify';
import { FaTimes } from 'react-icons/fa';
import {
	useGetProductDetailsQuery,
	useUpdateProductMutation,
	useUploadProductImageMutation,
} from '../../slices/productsApiSlice';
import Meta from '../../components/Meta';

const ProductEditScreen = () => {
	// Get product id from URL
	const { id: productId } = useParams();

	// Product edit form state
	const [name, setName] = useState('');
	const [price, setPrice] = useState(0);
	const [images, setImages] = useState([]);
	const [brand, setBrand] = useState('');
	const [category, setCategory] = useState('');
	const [countInStock, setCountInStock] = useState(0);
	const [description, setDescription] = useState('');

	// Fetch product details
	const {
		data: product,
		isLoading,
		refetch,
		error,
	} = useGetProductDetailsQuery(productId);

	// Update product mutation
	const [updateProduct, { isLoading: loadingUpdate }] =
		useUpdateProductMutation();

	// Upload product image mutation
	const [uploadProductImage, { isLoading: loadingUpload }] =
		useUploadProductImageMutation();

	// Navigation hook
	const navigate = useNavigate();

	// Submit handler
	const submitHandler = async (e) => {
		// Prevent page refresh
		e.preventDefault();
		// Update product with form state and navigate to product list page
		try {
			// Dispatch update product action
			await updateProduct({
				productId,
				name,
				price,
				images,
				brand,
				category,
				description,
				countInStock,
			});
			// Show success message
			toast.success('Product updated successfully');
			// Refetch product details and navigate to product list page
			refetch();
			navigate('/admin/productlist');
		} catch (err) {
			if (
				err?.data?.message === 'TypeError: Failed to fetch' ||
				err.error === 'TypeError: Failed to fetch'
			) {
				toast.error(
					'You are currently offline. Please connect to the internet and try again.',
				);
			} else if (err?.data?.message) {
				// Display the error message from the backend
				toast.error(err?.data?.message);
			} else if (err.status === 400) {
				toast.error('Bad request. Please check your input and try again.');
			} else if (err.status === 500) {
				toast.error('Internal server error. Please try again later.');
			} else {
				toast.error(
					err?.data?.message ||
						err.error ||
						'An unexpected error occurred. Please try again.',
				);
			}
		}
	};

	// Set product details to form state
	useEffect(() => {
		// If product is available, set product details to form state
		if (product) {
			setName(product.name);
			setPrice(product.price);
			setImages(product.images);
			setBrand(product.brand);
			setCategory(product.category);
			setCountInStock(product.countInStock);
			setDescription(product.description);
		}
	}, [product]);

	// Upload file handler
	const uploadFileHandler = async (e) => {
		// Create form data and append images to it
		const files = Array.from(e.target.files);
		const formData = new FormData();
		files.forEach((file) => {
			formData.append('images', file);
		});
		try {
			// Dispatch upload product image action
			const res = await uploadProductImage(formData).unwrap();
			toast.success(res.message);
			setImages([...images, ...res.images]);
		} catch (err) {
			if (
				err?.data?.message === 'TypeError: Failed to fetch' ||
				err.error === 'TypeError: Failed to fetch'
			) {
				toast.error(
					'You are currently offline. Please connect to the internet and try again.',
				);
			} else if (err?.data?.message) {
				// Display the error message from the backend
				toast.error(err?.data?.message);
			} else if (err.status === 400) {
				toast.error('Bad request. Please check your input and try again.');
			} else if (err.status === 500) {
				toast.error('Internal server error. Please try again later.');
			} else {
				toast.error(
					err?.data?.message ||
						err.error ||
						'An unexpected error occurred. Please try again.',
				);
			}
		}
	};

	// Remove image handler
	const removeImageHandler = (image) => {
		setImages(images.filter((img) => img !== image));
	};

	// Render JSX product edit form
	return (
		<>
			<Meta title='Edit Product - Cabsons Nutra Biohealth' />
			<Link to='/admin/productlist' className='btn btn-light my-3'>
				Go Back
			</Link>
			<FormContainer>
				<h1>Edit Product</h1>
				{loadingUpdate && <Loader />}
				{isLoading ? (
					<Loader />
				) : error ? (
					error?.data?.message === 'TypeError: Failed to fetch' ||
					error.error === 'TypeError: Failed to fetch' ? (
						<Message variant='danger'>
							You are currently offline. Product details are not available since
							you have not visited this page before while online. Please connect
							to the internet and try again.
						</Message>
					) : (
						<Message variant='danger'>
							{error?.data?.message ||
								error.error ||
								'An error occurred, please try again'}
						</Message>
					)
				) : (
					<Form onSubmit={submitHandler}>
						<Form.Group controlId='name'>
							<Form.Label>Name</Form.Label>
							<Form.Control
								type='name'
								placeholder='Enter name'
								value={name}
								onChange={(e) => setName(e.target.value)}></Form.Control>
						</Form.Group>

						<Form.Group controlId='price'>
							<Form.Label>Price</Form.Label>
							<Form.Control
								type='number'
								placeholder='Enter price'
								value={price}
								onChange={(e) => setPrice(e.target.value)}></Form.Control>
						</Form.Group>

						<Form.Group controlId='images'>
							<Form.Label>Images</Form.Label>
							<Row>
								{images.map((img, index) => (
									<Col
										key={index}
										lg={3}
										md={4}
										sm={6}
										xs={12}
										className='mb-3'>
										<Image src={img} alt={`Product Image ${index + 1}`} fluid />
										<Button
											variant='danger'
											onClick={() => removeImageHandler(img)}
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												marginTop: '10px',
											}}>
											<FaTimes />
										</Button>
									</Col>
								))}
							</Row>
							<Form.Control
								type='file'
								label='Choose Files'
								onChange={uploadFileHandler}
								accept='image/*'
								multiple></Form.Control>
							{loadingUpload && <Loader />}
						</Form.Group>

						<Form.Group controlId='brand'>
							<Form.Label>Brand</Form.Label>
							<Form.Control
								type='text'
								placeholder='Enter brand'
								value={brand}
								onChange={(e) => setBrand(e.target.value)}></Form.Control>
						</Form.Group>

						<Form.Group controlId='countInStock'>
							<Form.Label>Count In Stock</Form.Label>
							<Form.Control
								type='number'
								placeholder='Enter countInStock'
								value={countInStock}
								onChange={(e) =>
									setCountInStock(e.target.value)
								}></Form.Control>
						</Form.Group>

						<Form.Group controlId='category'>
							<Form.Label>Category</Form.Label>
							<Form.Select
								value={category}
								onChange={(e) => setCategory(e.target.value)}>
								<option value=''>-- Select Category --</option>
								<option value="Men's Health">Men's Health</option>
								<option value="Women's Health">Women's Health</option>
								<option value='Respiratory System'>Respiratory System</option>
								<option value='Metabolic System'>Metabolic System</option>
								<option value='Nervous System'>Nervous System</option>
								<option value='Skeletal System'>Skeletal System</option>
								<option value='Endocrine System'>Endocrine System</option>
								<option value='Immune System'>Immune System</option>
								<option value='Cardiovascular System'>
									Cardiovascular System
								</option>
								<option value='Anti-Aging'>Anti-Aging</option>
							</Form.Select>
						</Form.Group>

						<Form.Group controlId='description'>
							<Form.Label>Description</Form.Label>
							<Form.Control
								type='text'
								placeholder='Enter description'
								value={description}
								onChange={(e) => setDescription(e.target.value)}></Form.Control>
						</Form.Group>

						<Button
							type='submit'
							variant='primary'
							style={{ marginTop: '1rem' }}>
							Update
						</Button>
					</Form>
				)}
			</FormContainer>
		</>
	);
};

export default ProductEditScreen;
