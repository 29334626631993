import React from 'react';

const AboutUs = () => {
	return (
		<div className='about-us'>
			<h2>About Cabsons Nutra Biohealth</h2>
			<p>
				Welcome to Cabsons Nutra Biohealth, your ultimate destination for
				biohealth products! We specialize in a wide range of organic products
				designed to enhance your health and well-being. Whether you are looking
				for solutions for men's health, women's health, or specific systems like
				respiratory, metabolic, nervous, skeletal, endocrine, immune,
				cardiovascular, or anti-aging, we have you covered.
			</p>

			<h3>Best Prices, Premium Quality, and Diverse Categories</h3>
			<p>
				Our commitment is to provide you with high-quality organic products at
				unbeatable prices. Explore our extensive categories to find the products
				that suit your needs:
			</p>
			<ul>
				<li>Men's Health</li>
				<li>Women's Health</li>
				<li>Respiratory System</li>
				<li>Metabolic System</li>
				<li>Nervous System</li>
				<li>Skeletal System</li>
				<li>Endocrine System</li>
				<li>Immune System</li>
				<li>Cardiovascular System</li>
				<li>Anti-aging</li>
			</ul>

			<h3>Exceptional Services</h3>
			<p>
				We strive to make your shopping experience seamless and gratifying.
				Benefit from our delivery service, bringing your purchases directly to
				your doorstep.
			</p>

			<h3>FAQs</h3>
			<h4>How can I place an order?</h4>
			<p>
				Placing an order is easy! Simply browse our website, select the products
				you want, add them to your cart, and proceed to checkout. Follow the
				on-screen instructions to complete your purchase.
			</p>

			<h4>What payment methods do you accept?</h4>
			<p>
				We accept a variety of payment methods, including credit cards and
				mobile money
			</p>

			<h4>How long does delivery take?</h4>
			<p>
				Delivery times may vary depending on your location and the product. We
				strive to deliver your orders as quickly as possible.
			</p>

			<h4>Is my personal information safe?</h4>
			<p>
				Absolutely. We take data security seriously and have robust measures in
				place to protect your personal information. Your data is safe with us.
			</p>
		</div>
	);
};

export default AboutUs;
