import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useResetPasswordMutation } from '../slices/usersApiSlice';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const PasswordResetScreen = () => {
	const [resetPassword, { isLoading, isError, error, isSuccess }] =
		useResetPasswordMutation();
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const { token } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (isError) {
			if (
				error?.data?.message === 'TypeError: Failed to fetch' ||
				error.error === 'TypeError: Failed to fetch'
			) {
				toast.error(
					'You are currently offline. Please connect to the internet and try again.',
				);
			} else if (error?.data?.message) {
				// Display the error message from the backend
				toast.error(error?.data?.message);
			} else if (error.status === 400) {
				toast.error('Bad request. Please check your input and try again.');
			} else if (error.status === 500) {
				toast.error('Internal server error. Please try again later.');
			} else {
				toast.error(
					error?.data?.message ||
						error.message ||
						'An unexpected error occurred. Please try again',
				);
			}
		}
		if (isSuccess) {
			toast.success(
				'Password has been reset. You can now log in with your new password.',
			);
			navigate('/login');
		}
	}, [isError, isSuccess, error, navigate]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (password !== confirmPassword) {
			setPasswordError('Passwords do not match');
			return;
		} else if (password.length < 8) {
			setPasswordError('Password should be at least 8 characters long');
			return;
		} else if (!/\d/.test(password)) {
			setPasswordError('Password should contain at least one number');
			return;
		} else if (!/[a-zA-Z]/.test(password)) {
			setPasswordError('Password should contain at least one letter');
			return;
		} else if (!/^[A-Za-z\d@$!%*#?&]*$/.test(password)) {
			setPasswordError('Password contains invalid characters');
			return;
		} else {
			await resetPassword({ token, password });
			// Track the event
			window.gtag('event', 'reset_password', {
				event_category: 'User Interaction',
				event_label: 'Reset password',
			});
		}
	};

	return (
		<FormContainer>
			<Helmet>
				<title>Password Reset - Cabsons Nutra Biohealth</title>
				<meta name='description' content='Reset your password' />
			</Helmet>
			<h1>Password Reset</h1>
			<Form onSubmit={handleSubmit}>
				<Form.Group controlId='password' className='my-3'>
					<Form.Label>New Password</Form.Label>
					<Form.Control
						type='password'
						placeholder='Enter new password'
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required></Form.Control>
					<Form.Text className='text-muted'>
						Password should be at least 8 characters long and contain at least
						one letter and one number.
					</Form.Text>
					<br />
					{passwordError && (
						<Form.Text className='text-danger'>{passwordError}</Form.Text>
					)}
				</Form.Group>

				<Form.Group controlId='confirmPassword' className='my-3'>
					<Form.Label>Confirm New Password</Form.Label>
					<Form.Control
						type='password'
						placeholder='Confirm new password'
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
						required></Form.Control>
				</Form.Group>

				<Button
					type='submit'
					variant='primary'
					className='mt-2'
					disabled={isLoading}>
					Submit
				</Button>

				{isLoading && <Loader />}
			</Form>
		</FormContainer>
	);
};

export default PasswordResetScreen;
