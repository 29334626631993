import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const CTPaginate = ({ pageNumber, setPageNumber, data }) => {
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	useEffect(() => {
		window.addEventListener('resize', () => {
			setIsMobile(window.innerWidth <= 768);
		});
	}, []);

	const loadMoreProducts = () => {
		setPageNumber((prev) => prev + 1);
	};

	const loadPreviousProducts = () => {
		setPageNumber((prev) => prev - 1);
	};

	const goToPage = (page) => {
		setPageNumber(page);
	};

	const pageNumbers = data
		? [...Array(data.pages).keys()].map((x) => x + 1)
		: [];

	const visiblePageNumbers = pageNumbers.filter((page) =>
		isMobile
			? page >= pageNumber && page <= pageNumber + 1
			: Math.abs(pageNumber - page) <= 2,
	);

	const showPreviousEllipsis = isMobile ? pageNumber > 1 : pageNumber > 3;
	const showNextEllipsis =
		data &&
		(isMobile
			? visiblePageNumbers[visiblePageNumbers.length - 1] < data.pages
			: pageNumber < data.pages - 2);

	return (
		<div>
			{data && data.page > 1 && (
				<Button className='me-2' onClick={loadPreviousProducts}>
					<FaChevronLeft /> Previous
				</Button>
			)}
			{showPreviousEllipsis && (
				<span className='me-2' style={{ fontSize: '2em' }}>
					...
				</span>
			)}
			{visiblePageNumbers.map((page) => (
				<Button
					className={`me-2 my-1 ${
						page === pageNumber ? 'btn-primary' : 'btn-light'
					}`}
					key={page}
					onClick={() => goToPage(page)}>
					{page}
				</Button>
			))}
			{showNextEllipsis && <span style={{ fontSize: '2em' }}>...</span>}
			{data && data.page < data.pages && (
				<Button className='ms-2' onClick={loadMoreProducts}>
					Next <FaChevronRight />
				</Button>
			)}
		</div>
	);
};

export default CTPaginate;
