import { CONTACT_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const contactApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		// Define the API endpoints

		// Contact form submission endpoint
		submitContactForm: builder.mutation({
			query: (formData) => ({
				url: `${CONTACT_URL}`,
				method: 'POST',
				body: formData,
			}),
		}),

		// Get contacts endpoint
		getContacts: builder.query({
			query: (params) => ({
				url: `${CONTACT_URL}`,
				method: 'GET',
				params,
			}),
			providesTags: ['Contacts'],
		}),

		// Get contact form submission by ID endpoint
		getContactById: builder.query({
			query: (id) => ({
				url: `${CONTACT_URL}/${id}`,
				method: 'GET',
			}),
		}),

		// Delete contact form submission endpoint
		deleteContact: builder.mutation({
			query: (id) => ({
				url: `${CONTACT_URL}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Contacts'],
		}),

		// Reply to contact form submission endpoint
		replyContact: builder.mutation({
			query: ({ id, reply }) => ({
				url: `${CONTACT_URL}/${id}/reply`,
				method: 'POST',
				body: { reply },
			}),
		}),
	}),
});

export const {
	useSubmitContactFormMutation,
	useGetContactsQuery,
	useGetContactByIdQuery,
	useDeleteContactMutation,
	useReplyContactMutation,
} = contactApiSlice;
