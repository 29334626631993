import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import assets from '../config.js';

const NotFoundScreen = () => {
	return (
		<Container>
			<Row className='justify-content-md-center'>
				<Col xs lg='6'>
					<Image src={assets.pageNotFound} fluid />
				</Col>
			</Row>
			<Row className='justify-content-md-center text-center'>
				<Col xs lg='6'>
					<p>Sorry, the page you are looking for does not exist.</p>
					<p>
						You can always go back to the
						<Link to='/' className='btn btn-outline-primary ms-2'>
							{' '}
							homepage
						</Link>
						.
					</p>
				</Col>
			</Row>
		</Container>
	);
};

export default NotFoundScreen;
