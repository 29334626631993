import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormContainer from '../components/FormContainer';
import { saveShippingAddress } from '../slices/cartSlice';
import CheckoutSteps from '../components/CheckoutSteps';
import Meta from '../components/Meta';
import Creatable from 'react-select/creatable';

// Shipping screen component
const ShippingScreen = () => {
	// Get the shipping address from the Redux store
	const cart = useSelector((state) => state.cart);
	// Local state for the form fields
	const { shippingAddress } = cart;
	const [address, setAddress] = useState(shippingAddress?.address || '');
	const [city, setCity] = useState(shippingAddress?.city || '');
	const [postalCode, setPostalCode] = useState(
		shippingAddress?.postalCode || '',
	);
	const [country, setCountry] = useState(shippingAddress?.country || '');
	const [phoneNumber, setPhoneNumber] = useState(
		shippingAddress?.phoneNumber || '',
	);

	const countryToContinent = {
		Afghanistan: 'Asia',
		Albania: 'Europe',
		Algeria: 'Africa',
		Andorra: 'Europe',
		Angola: 'Africa',
		'Antigua and Barbuda': 'North America',
		Argentina: 'South America',
		Armenia: 'Asia',
		Australia: 'Oceania',
		Austria: 'Europe',
		Azerbaijan: 'Asia',
		Bahamas: 'North America',
		Bahrain: 'Asia',
		Bangladesh: 'Asia',
		Barbados: 'North America',
		Belarus: 'Europe',
		Belgium: 'Europe',
		Belize: 'North America',
		Benin: 'Africa',
		Bhutan: 'Asia',
		Bolivia: 'South America',
		'Bosnia and Herzegovina': 'Europe',
		Botswana: 'Africa',
		Brazil: 'South America',
		Brunei: 'Asia',
		Bulgaria: 'Europe',
		'Burkina Faso': 'Africa',
		Burundi: 'Africa',
		'Cabo Verde': 'Africa',
		Cambodia: 'Asia',
		Cameroon: 'Africa',
		Canada: 'North America',
		'Central African Republic': 'Africa',
		Chad: 'Africa',
		Chile: 'South America',
		China: 'Asia',
		Colombia: 'South America',
		Comoros: 'Africa',
		'Congo (Congo-Brazzaville)': 'Africa',
		'Costa Rica': 'North America',
		Croatia: 'Europe',
		Cuba: 'North America',
		Cyprus: 'Europe',
		'Czechia (Czech Republic)': 'Europe',
		Denmark: 'Europe',
		Djibouti: 'Africa',
		Dominica: 'North America',
		'Dominican Republic': 'North America',
		Ecuador: 'South America',
		Egypt: 'Africa',
		'El Salvador': 'North America',
		'Equatorial Guinea': 'Africa',
		Eritrea: 'Africa',
		Estonia: 'Europe',
		'Eswatini (fmr. "Swaziland")': 'Africa',
		Ethiopia: 'Africa',
		Fiji: 'Oceania',
		Finland: 'Europe',
		France: 'Europe',
		Gabon: 'Africa',
		Gambia: 'Africa',
		Georgia: 'Asia',
		Germany: 'Europe',
		Ghana: 'Africa',
		Greece: 'Europe',
		Grenada: 'North America',
		Guatemala: 'North America',
		Guinea: 'Africa',
		'Guinea-Bissau': 'Africa',
		Guyana: 'South America',
		Haiti: 'North America',
		Honduras: 'North America',
		Hungary: 'Europe',
		Iceland: 'Europe',
		India: 'Asia',
		Indonesia: 'Asia',
		Iran: 'Asia',
		Iraq: 'Asia',
		Ireland: 'Europe',
		Israel: 'Asia',
		Italy: 'Europe',
		Jamaica: 'North America',
		Japan: 'Asia',
		Jordan: 'Asia',
		Kazakhstan: 'Asia',
		Kenya: 'Africa',
		Kiribati: 'Oceania',
		'Korea, North': 'Asia',
		'Korea, South': 'Asia',
		Kosovo: 'Europe',
		Kuwait: 'Asia',
		Kyrgyzstan: 'Asia',
		Laos: 'Asia',
		Latvia: 'Europe',
		Lebanon: 'Asia',
		Lesotho: 'Africa',
		Liberia: 'Africa',
		Libya: 'Africa',
		Liechtenstein: 'Europe',
		Lithuania: 'Europe',
		Luxembourg: 'Europe',
		Madagascar: 'Africa',
		Malawi: 'Africa',
		Malaysia: 'Asia',
		Maldives: 'Asia',
		Mali: 'Africa',
		Malta: 'Europe',
		'Marshall Islands': 'Oceania',
		Mauritania: 'Africa',
		Mauritius: 'Africa',
		Mexico: 'North America',
		Micronesia: 'Oceania',
		Moldova: 'Europe',
		Monaco: 'Europe',
		Mongolia: 'Asia',
		Montenegro: 'Europe',
		Morocco: 'Africa',
		Mozambique: 'Africa',
		'Myanmar (formerly Burma)': 'Asia',
		Namibia: 'Africa',
		Nauru: 'Oceania',
		Nepal: 'Asia',
		Netherlands: 'Europe',
		'New Zealand': 'Oceania',
		Nicaragua: 'North America',
		Niger: 'Africa',
		Nigeria: 'Africa',
		'North Macedonia (formerly Macedonia)': 'Europe',
		Norway: 'Europe',
		Oman: 'Asia',
		Pakistan: 'Asia',
		Palau: 'Oceania',
		'Palestine State': 'Asia',
		Panama: 'North America',
		'Papua New Guinea': 'Oceania',
		Paraguay: 'South America',
		Peru: 'South America',
		Philippines: 'Asia',
		Poland: 'Europe',
		Portugal: 'Europe',
		Qatar: 'Asia',
		Romania: 'Europe',
		Russia: 'Europe',
		Rwanda: 'Africa',
		'Saint Kitts and Nevis': 'North America',
		'Saint Lucia': 'North America',
		'Saint Vincent and the Grenadines': 'North America',
		Samoa: 'Oceania',
		'San Marino': 'Europe',
		'Sao Tome and Principe': 'Africa',
		'Saudi Arabia': 'Asia',
		Senegal: 'Africa',
		Serbia: 'Europe',
		Seychelles: 'Africa',
		'Sierra Leone': 'Africa',
		Singapore: 'Asia',
		Slovakia: 'Europe',
		Slovenia: 'Europe',
		'Solomon Islands': 'Oceania',
		Somalia: 'Africa',
		'South Africa': 'Africa',
		'South Sudan': 'Africa',
		Spain: 'Europe',
		'Sri Lanka': 'Asia',
		Sudan: 'Africa',
		Suriname: 'South America',
		Sweden: 'Europe',
		Switzerland: 'Europe',
		Syria: 'Asia',
		Taiwan: 'Asia',
		Tajikistan: 'Asia',
		Tanzania: 'Africa',
		Thailand: 'Asia',
		'Timor-Leste': 'Asia',
		Togo: 'Africa',
		Tonga: 'Oceania',
		'Trinidad and Tobago': 'North America',
		Tunisia: 'Africa',
		Turkey: 'Asia',
		Turkmenistan: 'Asia',
		Tuvalu: 'Oceania',
		Uganda: 'Africa',
		Ukraine: 'Europe',
		'United Arab Emirates': 'Asia',
		'United Kingdom': 'Europe',
		'United States of America': 'North America',
		Uruguay: 'South America',
		Uzbekistan: 'Asia',
		Vanuatu: 'Oceania',
		'Vatican City': 'Europe',
		Venezuela: 'South America',
		Vietnam: 'Asia',
		Yemen: 'Asia',
		Zambia: 'Africa',
		Zimbabwe: 'Africa',
	};

	const countryOptions = Object.keys(countryToContinent).map((country) => ({
		value: country,
		label: country,
	}));

	// Dispatch function from the Redux store
	const dispatch = useDispatch();
	// Function to navigate to a different page
	const navigate = useNavigate();

	// Function to handle the form submit
	const submitHandler = (e) => {
		e.preventDefault();
		dispatch(
			saveShippingAddress({ address, city, postalCode, country, phoneNumber }),
		);
		// Track the event
		window.gtag('event', 'submit_shipping_info', {
			event_category: 'User Interaction',
			event_label: 'Submitted shipping information',
		});
		navigate('/payment');
	};

	return (
		<>
			<Meta
				title='Shipping Information'
				description='Enter your shipping address to proceed with your order'
				keywords='shipping address, order, proceed'
				image={`${window.location.origin}/images/cabsons-512.png`}
			/>

			<FormContainer>
				<CheckoutSteps step1 step2 />

				<h1>Shipping</h1>
				<Form onSubmit={submitHandler}>
					<Form.Group className='my-2' controlId='phoneNumber'>
						<Form.Label>Phone Number</Form.Label>
						<Form.Control
							type='text'
							placeholder='Enter phone number'
							value={phoneNumber}
							required
							onChange={(e) => setPhoneNumber(e.target.value)}></Form.Control>
					</Form.Group>

					<Form.Group className='my-2' controlId='address'>
						<Form.Label>Address</Form.Label>
						<Form.Control
							type='text'
							placeholder='Enter address'
							value={address}
							required
							onChange={(e) => setAddress(e.target.value)}></Form.Control>
					</Form.Group>

					<Form.Group className='my-2' controlId='city'>
						<Form.Label>City</Form.Label>
						<Form.Control
							type='text'
							placeholder='Enter city'
							value={city}
							required
							onChange={(e) => setCity(e.target.value)}></Form.Control>
					</Form.Group>

					<Form.Group className='my-2' controlId='postalCode'>
						<Form.Label>Postal Code</Form.Label>
						<Form.Control
							type='text'
							placeholder='Enter postal code'
							value={postalCode}
							required
							onChange={(e) => setPostalCode(e.target.value)}></Form.Control>
					</Form.Group>

					<Form.Group className='my-2' controlId='country'>
						<Form.Label>Country</Form.Label>
						<Creatable
							options={countryOptions}
							value={
								countryOptions.find((option) => option.value === country) ||
								country
							}
							onChange={(selectedOption) => setCountry(selectedOption.value)}
							isSearchable
						/>
					</Form.Group>

					<Button type='submit' variant='primary'>
						Continue
					</Button>
				</Form>
			</FormContainer>
		</>
	);
};

export default ShippingScreen;
