const countryToContinent = {
	Afghanistan: 'Asia',
	Albania: 'Europe',
	Algeria: 'Africa',
	Andorra: 'Europe',
	Angola: 'Africa',
	'Antigua and Barbuda': 'North America',
	Argentina: 'South America',
	Armenia: 'Asia',
	Australia: 'Oceania',
	Austria: 'Europe',
	Azerbaijan: 'Asia',
	Bahamas: 'North America',
	Bahrain: 'Asia',
	Bangladesh: 'Asia',
	Barbados: 'North America',
	Belarus: 'Europe',
	Belgium: 'Europe',
	Belize: 'North America',
	Benin: 'Africa',
	Bhutan: 'Asia',
	Bolivia: 'South America',
	'Bosnia and Herzegovina': 'Europe',
	Botswana: 'Africa',
	Brazil: 'South America',
	Brunei: 'Asia',
	Bulgaria: 'Europe',
	'Burkina Faso': 'Africa',
	Burundi: 'Africa',
	'Cabo Verde': 'Africa',
	Cambodia: 'Asia',
	Cameroon: 'Africa',
	Canada: 'North America',
	'Central African Republic': 'Africa',
	Chad: 'Africa',
	Chile: 'South America',
	China: 'Asia',
	Colombia: 'South America',
	Comoros: 'Africa',
	'Congo (Congo-Brazzaville)': 'Africa',
	'Costa Rica': 'North America',
	Croatia: 'Europe',
	Cuba: 'North America',
	Cyprus: 'Europe',
	'Czechia (Czech Republic)': 'Europe',
	Denmark: 'Europe',
	Djibouti: 'Africa',
	Dominica: 'North America',
	'Dominican Republic': 'North America',
	Ecuador: 'South America',
	Egypt: 'Africa',
	'El Salvador': 'North America',
	'Equatorial Guinea': 'Africa',
	Eritrea: 'Africa',
	Estonia: 'Europe',
	'Eswatini (fmr. "Swaziland")': 'Africa',
	Ethiopia: 'Africa',
	Fiji: 'Oceania',
	Finland: 'Europe',
	France: 'Europe',
	Gabon: 'Africa',
	Gambia: 'Africa',
	Georgia: 'Asia',
	Germany: 'Europe',
	Ghana: 'Africa',
	Greece: 'Europe',
	Grenada: 'North America',
	Guatemala: 'North America',
	Guinea: 'Africa',
	'Guinea-Bissau': 'Africa',
	Guyana: 'South America',
	Haiti: 'North America',
	Honduras: 'North America',
	Hungary: 'Europe',
	Iceland: 'Europe',
	India: 'Asia',
	Indonesia: 'Asia',
	Iran: 'Asia',
	Iraq: 'Asia',
	Ireland: 'Europe',
	Israel: 'Asia',
	Italy: 'Europe',
	Jamaica: 'North America',
	Japan: 'Asia',
	Jordan: 'Asia',
	Kazakhstan: 'Asia',
	Kenya: 'Africa',
	Kiribati: 'Oceania',
	'Korea, North': 'Asia',
	'Korea, South': 'Asia',
	Kosovo: 'Europe',
	Kuwait: 'Asia',
	Kyrgyzstan: 'Asia',
	Laos: 'Asia',
	Latvia: 'Europe',
	Lebanon: 'Asia',
	Lesotho: 'Africa',
	Liberia: 'Africa',
	Libya: 'Africa',
	Liechtenstein: 'Europe',
	Lithuania: 'Europe',
	Luxembourg: 'Europe',
	Madagascar: 'Africa',
	Malawi: 'Africa',
	Malaysia: 'Asia',
	Maldives: 'Asia',
	Mali: 'Africa',
	Malta: 'Europe',
	'Marshall Islands': 'Oceania',
	Mauritania: 'Africa',
	Mauritius: 'Africa',
	Mexico: 'North America',
	Micronesia: 'Oceania',
	Moldova: 'Europe',
	Monaco: 'Europe',
	Mongolia: 'Asia',
	Montenegro: 'Europe',
	Morocco: 'Africa',
	Mozambique: 'Africa',
	'Myanmar (formerly Burma)': 'Asia',
	Namibia: 'Africa',
	Nauru: 'Oceania',
	Nepal: 'Asia',
	Netherlands: 'Europe',
	'New Zealand': 'Oceania',
	Nicaragua: 'North America',
	Niger: 'Africa',
	Nigeria: 'Africa',
	'North Macedonia (formerly Macedonia)': 'Europe',
	Norway: 'Europe',
	Oman: 'Asia',
	Pakistan: 'Asia',
	Palau: 'Oceania',
	'Palestine State': 'Asia',
	Panama: 'North America',
	'Papua New Guinea': 'Oceania',
	Paraguay: 'South America',
	Peru: 'South America',
	Philippines: 'Asia',
	Poland: 'Europe',
	Portugal: 'Europe',
	Qatar: 'Asia',
	Romania: 'Europe',
	Russia: 'Europe',
	Rwanda: 'Africa',
	'Saint Kitts and Nevis': 'North America',
	'Saint Lucia': 'North America',
	'Saint Vincent and the Grenadines': 'North America',
	Samoa: 'Oceania',
	'San Marino': 'Europe',
	'Sao Tome and Principe': 'Africa',
	'Saudi Arabia': 'Asia',
	Senegal: 'Africa',
	Serbia: 'Europe',
	Seychelles: 'Africa',
	'Sierra Leone': 'Africa',
	Singapore: 'Asia',
	Slovakia: 'Europe',
	Slovenia: 'Europe',
	'Solomon Islands': 'Oceania',
	Somalia: 'Africa',
	'South Africa': 'Africa',
	'South Sudan': 'Africa',
	Spain: 'Europe',
	'Sri Lanka': 'Asia',
	Sudan: 'Africa',
	Suriname: 'South America',
	Sweden: 'Europe',
	Switzerland: 'Europe',
	Syria: 'Asia',
	Taiwan: 'Asia',
	Tajikistan: 'Asia',
	Tanzania: 'Africa',
	Thailand: 'Asia',
	'Timor-Leste': 'Asia',
	Togo: 'Africa',
	Tonga: 'Oceania',
	'Trinidad and Tobago': 'North America',
	Tunisia: 'Africa',
	Turkey: 'Asia',
	Turkmenistan: 'Asia',
	Tuvalu: 'Oceania',
	Uganda: 'Africa',
	Ukraine: 'Europe',
	'United Arab Emirates': 'Asia',
	'United Kingdom': 'Europe',
	'United States of America': 'North America',
	Uruguay: 'South America',
	Uzbekistan: 'Asia',
	Vanuatu: 'Oceania',
	'Vatican City': 'Europe',
	Venezuela: 'South America',
	Vietnam: 'Asia',
	Yemen: 'Asia',
	Zambia: 'Africa',
	Zimbabwe: 'Africa',
};

const shippingRates = {
	'North America': 6,
	Europe: 6,
	Asia: 6,
	Africa: 6,
	'South America': 6,
	Oceania: 6,
	Ghana: 6,
	Default: 6,
};

function getContinent(country) {
	return shippingRates[country]
		? country
		: countryToContinent[country] || 'Default';
}

export const addDecimals = (num) => {
	return (Math.round(num * 100) / 100).toFixed(2);
};

export const updateCart = (state) => {
	const itemsPrice = state.cartItems.reduce(
		(acc, item) => acc + (item.price * 100 * item.qty) / 100,
		0,
	);
	state.itemsPrice = addDecimals(itemsPrice);

	const { country } = state.shippingAddress;
	const totalBottles = state.cartItems.reduce(
		(total, item) => total + item.qty,
		0,
	);

	const continent = getContinent(country);
	let ratePerBottle = shippingRates[continent] || shippingRates['Default'];

	if (totalBottles >= 5) {
		ratePerBottle *= 0.8;
	} else if (totalBottles >= 3) {
		ratePerBottle *= 0.9;
	}

	const shippingPrice = addDecimals(totalBottles * ratePerBottle);
	state.shippingPrice = shippingPrice;

	const totalPrice = (Number(itemsPrice) + Number(shippingPrice)).toFixed(2);
	state.totalPrice = totalPrice;

	localStorage.setItem('cart', JSON.stringify(state));

	return state;
};

// SECOND ITERATION

// export const updateCart = (state) => {
// 	const itemsPrice = state.cartItems.reduce(
// 		(acc, item) => acc + (item.price * 100 * item.qty) / 100,
// 		0,
// 	);
// 	state.itemsPrice = addDecimals(itemsPrice);

// 	const { country } = state.shippingAddress;
// 	const totalBottles = state.cartItems.reduce(
// 		(total, item) => total + item.qty,
// 		0,
// 	);

// 	const continent = getContinent(country);
// 	const ratePerBottle = shippingRates[continent] || shippingRates['Default'];
// 	let totalWeight = totalBottles * defaultWeightPerBottle;

// 	if (totalBottles >= 5) {
// 		totalWeight *= 0.8;
// 	} else if (totalBottles >= 3) {
// 		totalWeight *= 0.9;
// 	}

// 	const shippingPrice = addDecimals(totalWeight * ratePerBottle);
// 	state.shippingPrice = shippingPrice;

// 	const totalPrice = (Number(itemsPrice) + Number(shippingPrice)).toFixed(2);
// 	state.totalPrice = totalPrice;

// 	localStorage.setItem('cart', JSON.stringify(state));

// 	return state;
// };

// export const addDecimals = (num) => {
// 	return (Math.round(num * 100) / 100).toFixed(2);
// };

// FIRST ITERATION

// export const updateCart = (state) => {
// 	// Calculate the items price in whole number to avoid issues with
// 	// floating point number calculations
// 	const itemsPrice = state.cartItems.reduce(
// 		(acc, item) => acc + (item.price * 100 * item.qty) / 100,
// 		0,
// 	);
// 	state.itemsPrice = addDecimals(itemsPrice);

// 	// Calculate the shipping price
// 	const shippingPrice = itemsPrice < 100 ? 20 : 25;
// 	state.shippingPrice = addDecimals(shippingPrice);

// 	const totalPrice = itemsPrice + shippingPrice;
// 	// Calculate the total price
// 	state.totalPrice = addDecimals(totalPrice);

// 	// Save the cart to localStorage
// 	localStorage.setItem('cart', JSON.stringify(state));

// 	return state;
// };
