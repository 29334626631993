import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useVerifyEmailMutation } from '../slices/usersApiSlice';
import Loader from '../components/Loader';
import Meta from '../components/Meta';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'; // Import icons

const EmailVerificationScreen = () => {
	const { token } = useParams();
	const navigate = useNavigate();
	const [verifyEmail, { isLoading, isError, isSuccess }] =
		useVerifyEmailMutation();
	const [buttonClicked, setButtonClicked] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const verifyEmailAsync = async () => {
			if (buttonClicked) {
				try {
					const result = await verifyEmail(token).unwrap();

					if (result.message === 'Email verified successfully') {
						toast.success('Email verification successful. You can now login.');
						navigate('/login');
					} else if (result.message === 'Invalid token') {
						toast.error(
							'The verification link has expired or is invalid. Please request a new verification email.',
						);
					} else if (result.message === 'Expired token') {
						toast.error(
							'The verification link has expired or is invalid. Please request a new verification email.',
						);
					} else {
						toast.error(result.message || 'Email verification failed');
					}
				} catch (error) {
					if (
						error?.data?.message === 'TypeError: Failed to fetch' ||
						error.error === 'TypeError: Failed to fetch'
					) {
						toast.error(
							'You are currently offline. Please connect to the internet and try again.',
						);
					} else if (error?.data?.message) {
						// Display the error message from the backend
						toast.error(error?.data?.message);
					} else if (error.status === 400) {
						toast.error('Bad request. Please check your input and try again.');
					} else if (error.status === 500) {
						toast.error('Internal server error. Please try again later.');
					} else {
						toast.error(
							error.data?.message ||
								error.message ||
								'An unexpected error occurred. Please try again.',
						);
					}
				}
			}
		};

		verifyEmailAsync();
	}, [verifyEmail, token, navigate, buttonClicked]);

	return (
		<Container>
			<Row className='justify-content-md-center'>
				<Col md='auto'>
					<Meta
						title='Email Verification - Cabsons Nutra Biohealth'
						description='Email verification page'
						keywords='email, verification'
					/>
					<Helmet>
						<script type='application/ld+json'>
							{JSON.stringify({
								'@context': 'https://schema.org',
								'@type': 'WebPage',
								url: window.location.href,
								name: 'Email Verification',
								description: 'Email verification page',
							})}
						</script>
					</Helmet>

					<h1>Email Verification</h1>
					<p>
						Please click the 'Verify Email' button to confirm your email
						address.
					</p>

					{isLoading ? (
						<Loader /> // Show the Loader component when isLoading is true
					) : isError ? (
						<>
							<h2>
								<FaTimesCircle color='red' /> Error: Email verification failed
							</h2>
						</>
					) : isSuccess && !isError ? (
						<>
							<h2>
								<FaCheckCircle color='green' /> Email Verified Successfully
							</h2>
						</>
					) : (
						<Button onClick={() => setButtonClicked(true)}>Verify Email</Button>
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default EmailVerificationScreen;
